import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

import { ComponentsEvent, ComponentsEventEnum } from './events';

const initialState: {
  events: Array<ComponentsEvent>;
  lastAction: `ADD` | `REMOVE` | null;
  lastModifiedEventType: ComponentsEventEnum | null;
} = {
  events: [],
  lastAction: null,
  lastModifiedEventType: null,
};

export const eventsSlice = createSlice({
  name: `events`,
  initialState,
  reducers: {
    addEvent: (state, action: PayloadAction<ComponentsEvent>) => {
      const events = [...state.events];
      const prevEventIndex = events.findIndex(
        (event) => event.type === action.payload.type,
      );
      if (prevEventIndex > 0) {
        events.splice(prevEventIndex, 1);
      }
      events.push(action.payload);
      state.events = events;
      state.lastAction = `ADD`;
      state.lastModifiedEventType = action.payload.type;
    },
    removeEvent: (state, action: PayloadAction<ComponentsEventEnum>) => {
      const events = [...state.events];
      state.events = events.filter((event) => event.type !== action.payload);
      state.lastAction = `REMOVE`;
      state.lastModifiedEventType = action.payload;
    },
  },
});

export const { addEvent, removeEvent } = eventsSlice.actions;
export const selectEvents = (state: RootState) => state.events;
export default eventsSlice.reducer;
