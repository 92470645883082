import axios from 'axios';

import { SaveMockTestDto } from '@/components/IELTSPrep/Mocktest/MockTest/redux/mockTestSlice';

const fetchMockTestDetails = (mockTestId: string) =>
  axios.get(`/api/scholarRoute?path=test-prep/partner/exam/${mockTestId}`);

const submitMockTestApi = (mockTestData: SaveMockTestDto) =>
  axios.post(`/api/geebeeRoute?path=/test-prep/submit`, {
    examId: mockTestData.mockTestId,
    answersByQuestionId: mockTestData.data,
  });

const fetchIeltsScore = async (examId: string) =>
  axios.get(
    `/api/geebeeRoute?path=/test-prep/exam-submission/${examId}/score-card`,
  );

export const mockTestApi = {
  fetchMockTestDetails,
  submitMockTestApi,
  fetchIeltsScore,
};
