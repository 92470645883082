import { ReactNode } from 'react';

import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { GBModalWrapper } from '@leapfinance/geebee-component-library';
import { ThemeProvider } from '@mui/system';

import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import useModal from '@/components/Modals/useModal';

export interface UpdateInfoModalProps {
  modalTitle?: string;
  modalContent?: ReactNode;
}

const UpdateInfoModal = (props: UpdateInfoModalProps) => {
  const { modalTitle, modalContent } = props;
  const { hideModal } = useModal();
  return (
    <GBModalWrapper
      headerProps={{
        title: modalTitle,
        onClose: () => hideModal(MODAL_TYPES.UPDATE_INFO_MODAL),
      }}
      variant="small"
    >
      <ThemeProvider theme={GeebeeTheme}>{modalContent}</ThemeProvider>
    </GBModalWrapper>
  );
};

export default UpdateInfoModal;
