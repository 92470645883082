import { call, put, takeLatest } from 'redux-saga/effects';

import { IeltsScoreCardV2 } from '@/components/IELTSPrep/Mocktest/Interface/MockTest';
import { mockTestApi } from '@/components/IELTSPrep/Mocktest/MockTest/redux/MockTestApi';
import {
  fetchIeltsScoreAction,
  fetchMockTestDataAction,
  setBandAndUpsellBannerDto,
  setIeltsScoreData,
  setMockTestData,
  setMockTestError,
  setMockTestIsLoading,
  submitMockTestData,
  updateSaveMockTestStatus,
} from '@/components/IELTSPrep/Mocktest/MockTest/redux/mockTestSlice';

function* fetchMockTestSaga(action: any): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true));
    const mockTestData = yield call(
      mockTestApi.fetchMockTestDetails,
      action.payload,
    );
    if (mockTestData.data.success) {
      yield put(setMockTestData(mockTestData.data.data));
    }
    yield put(setMockTestIsLoading(false));
  } catch (e: any) {
    yield put(setMockTestIsLoading(false));
    yield put(setMockTestError(`something went wrong`));
  }
}

function* submitMockTestDataSaga(action: any): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true));
    const res = yield call(mockTestApi.submitMockTestApi, action.payload);
    yield put(setMockTestIsLoading(false));
    if (res.data.success) {
      const result: IeltsScoreCardV2 = res.data.data?.scoreCard;
      const ieltsScoreCard = result.ieltsScoreCard.map((data) => ({
        ...data,
        isRetakeEligible: result.isRetakeEligible,
        examType: result.examType,
        examSubmissionId: res.data.data?.examSubmissionId,
        examName: res.data?.examName,
      }));
      yield put(setIeltsScoreData(ieltsScoreCard));
      yield put(updateSaveMockTestStatus(true));
    } else {
      yield put(setMockTestError(`something went wrong`));
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false));
    yield put(setMockTestError(`something went wrong`));
  }
}

function* fetchIeltsScoreSaga(action: any): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true));
    const res = yield call(mockTestApi.fetchIeltsScore, action.payload);
    yield put(setMockTestIsLoading(false));
    if (res?.data?.success) {
      const result: IeltsScoreCardV2 = res?.data?.data;
      const examName = res.data.data?.examName;
      const ieltsScoreCard = result.ieltsScoreCard.map((data) => ({
        ...data,
        isRetakeEligible: result.isRetakeEligible,
        examType: result.examType,
        examName: examName,
        examId: res.data.data?.examId,
      }));
      const bandAndUpsellBannerDto = res.data.data?.bandAndUpsellBannerDto;
      yield put(setBandAndUpsellBannerDto(bandAndUpsellBannerDto));
      yield put(setIeltsScoreData(ieltsScoreCard));
    } else {
      yield put(setMockTestError(res?.data?.message));
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false));
    yield put(setMockTestError(`something went wrong`));
  }
}

function* satDiagnosticSaga() {
  yield takeLatest(fetchMockTestDataAction, fetchMockTestSaga);
  yield takeLatest(submitMockTestData, submitMockTestDataSaga);
  yield takeLatest(fetchIeltsScoreAction, fetchIeltsScoreSaga);
}

export default satDiagnosticSaga;
