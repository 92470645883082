export const cspLogoUploadInstructionsList = [
  `Upload .jpg / .jpeg / .png / .svg file.`,
  `Height 40 px and Width 150 px.`,
  `File size should not exceed 2 mb.`,
];

export const cspLogoSupportedFormatsList = [
  `image/png`,
  `image/jpeg`,
  `image/jpg`,
  `image/svg+xml`,
];

export enum CspLogoDimensionsEnum {
  '150px' = 150,
  '40px' = 40,
}
