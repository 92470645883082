import { trackClick, trackPage } from '@/segment/eventTracker';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';

export enum ApplicationEligibilityEventType {
  STUDENT_PROFILE_COMPLETENESS,
  COLLEGE_ELIGIBILITY_WARNING,
  COMPLETE_STUDENT_PROFILE,
  EXPAND_COLLEGE_ELIGIBILITY,
  ELIGIBILITY_TAG_DISPLAYED,
  EXPAND_STUDENT_ELIGIBILITY,
  REDIDRECT_TO_CF,
  UPLOAD_DOCUMENT,
  CREATE_APPLICATION,
  TRIGGER_REQUIRED_DOCUMENT_UPLOAD_CHANGE,
  NON_PARTNERED_INSTITUTE_CF_NAVIGATION,
  PROGRAM_STATUS_CF_NAVIGATION,
  PROGRAM_STATUS_BANNER_DISPLAYED,
  NON_PARTNERED_INSTITUTE_CHIP_DISPLAYED,
  NON_PARTNERED_COURSE_CHIP_DISPLAYED,
  PROGRAM_STATUS_CHIP_DISPLAYED,
}

export const ApplicationEligibilityTrackPageEventWrapper = (
  eventType: ApplicationEligibilityEventType,
  rest?: any,
) => {
  let pageName;
  switch (eventType) {
    case ApplicationEligibilityEventType.STUDENT_PROFILE_COMPLETENESS:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      break;
    case ApplicationEligibilityEventType.COLLEGE_ELIGIBILITY_WARNING:
      pageName = PageNames.COLLEGE_ELIGIBILITY_WARNING;
      break;
  }
  trackPage({ pageName, ...rest });
};

export const ApplicationEligibilityClickEventWrapper = (
  eventType: ApplicationEligibilityEventType,
  rest?: any,
) => {
  let pageName;
  let widgetFormat;
  let widgetName;
  let contentFormat;
  let contentName;
  switch (eventType) {
    case ApplicationEligibilityEventType.COMPLETE_STUDENT_PROFILE:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.STUDENT_PROFILE_INCOMPLETE;
      widgetFormat = WidgetFormat.WARNING_MESSAGE;
      contentName = `Complete Student Profile`;
      contentFormat = `Hyperlinked Text`;
      break;

    case ApplicationEligibilityEventType.EXPAND_STUDENT_ELIGIBILITY:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.WHY_NON_ELIGIBLE;
      widgetFormat = WidgetFormat.WARNING_MESSAGE;
      contentName = `Why Non Eligible`;
      contentFormat = `Why Non Eligible`;
      break;

    case ApplicationEligibilityEventType.EXPAND_COLLEGE_ELIGIBILITY:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.COURSE_ELIGIBILITY_CHECK;
      widgetFormat = WidgetFormat.WARNING_MESSAGE;
      contentName = `Check Eligibility Criteria`;
      contentFormat = `Check Eligibility Criteria`;
      break;

    case ApplicationEligibilityEventType.REDIDRECT_TO_CF:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.CHECK_ELIGIBLE_COURSES_ON_CF;
      widgetFormat = WidgetFormat.BUTTON;
      contentName = `Check on CF`;
      contentFormat = `Button`;
      break;

    case ApplicationEligibilityEventType.UPLOAD_DOCUMENT:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.UPLOAD_DOCUMENT;
      widgetFormat = WidgetFormat.DOCUMENT_UPLOAD_CHIP;
      contentName = rest?.contentName;
      contentFormat = `Document Upload Chip`;
      break;

    case ApplicationEligibilityEventType.ELIGIBILITY_TAG_DISPLAYED:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.STUDENT_ELIGIBILITY_TAG;
      widgetFormat = WidgetFormat.TAG;
      contentName = `Student Eligibility Tag`;
      contentFormat = `Tag`;
      break;

    case ApplicationEligibilityEventType.CREATE_APPLICATION:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.ADD_NEW_APPLICATION;
      widgetFormat = WidgetFormat.MODAL;
      contentFormat = `Badge`;
      contentName = `Create Application`;
      break;

    case ApplicationEligibilityEventType.TRIGGER_REQUIRED_DOCUMENT_UPLOAD_CHANGE:
      pageName = PageNames.APPLICATION_CREATION_MODAL;
      widgetName = WidgetName.REQUIRED_DOCUMENT_MODAL;
      widgetFormat = WidgetFormat.MODAL;
      contentName = `Raise Application Button`;
      contentFormat = `Button`;
      break;
    case ApplicationEligibilityEventType.NON_PARTNERED_INSTITUTE_CF_NAVIGATION:
      widgetName = WidgetName.COURSE_NAME_DROPDOWN;
      widgetFormat = WidgetFormat.DROPDOWN;
      contentName = `No Tie Up Course CF redirection`;
      contentFormat = `Chip`;
      break;
    case ApplicationEligibilityEventType.PROGRAM_STATUS_CF_NAVIGATION:
      widgetName = WidgetName.COURSE_DROPDOWN;
      widgetFormat = WidgetFormat.DROPDOWN;
      contentName = `Closed program redirection to CF`;
      contentFormat = `Chip`;
      break;
    case ApplicationEligibilityEventType.PROGRAM_STATUS_BANNER_DISPLAYED:
      widgetName = WidgetName.UNIVERSITY_DROPDOWN;
      widgetFormat = WidgetFormat.DROPDOWN;
      contentName = `Closed University`;
      contentFormat = `Chip`;
      break;
    case ApplicationEligibilityEventType.NON_PARTNERED_INSTITUTE_CHIP_DISPLAYED:
      widgetName = WidgetName.UNIVERSITY_DROPDOWN;
      widgetFormat = WidgetFormat.DROPDOWN;
      contentName = `No Tie Up University`;
      contentFormat = `Chip`;
      break;
    case ApplicationEligibilityEventType.NON_PARTNERED_COURSE_CHIP_DISPLAYED:
      widgetName = WidgetName.COURSE_NAME_DROPDOWN;
      widgetFormat = WidgetFormat.DROPDOWN;
      contentName = `No Tie Up Course`;
      contentFormat = `Chip`;
      break;
    case ApplicationEligibilityEventType.PROGRAM_STATUS_CHIP_DISPLAYED:
      widgetName = WidgetName.COURSE_DROPDOWN;
      widgetFormat = WidgetFormat.DROPDOWN;
      contentName = `Closed Program`;
      contentFormat = `Chip`;
      break;
  }

  if (widgetFormat && widgetName) {
    trackClick({
      pageName,
      widgetFormat,
      widgetName,
      contentFormat,
      contentName,
      ...rest,
    });
  }
};
