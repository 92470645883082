import React, { useState } from 'react';

import {
  isFalsy,
  ValueChipTexFieldWithState,
} from '@leapfinance/frontend-commons';
import { type ValueChipTexFieldWithStateProps } from '@leapfinance/frontend-commons/components/ValueChipTexFieldWithState';
import { Button, Divider } from '@mui/material';

import { MODAL_TYPES } from './GlobalModal';
import useModal from './useModal';

export interface IEmailConfirmationModal
  extends Omit<ValueChipTexFieldWithStateProps, 'validateInput'> {
  onSubmit: (emails: string[]) => void;
}
const EmailConfirmationModal: React.FC<IEmailConfirmationModal> = ({
  displayMax,
  valueSuggestions,
  values,
  minRequired,
  disableDefaultValuesDelete,
  onSubmit,
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const { hideModal } = useModal();
  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="bg-white rounded-lg w-[70%] lg:w-[40%] overflow-y-scroll max-h-[90%]">
        <div className="flex items-center justify-between sticky top-0 bg-white z-50">
          <div className="p-4">
            <p className="font-semibold text-grey-800">
              Please confirm your email
            </p>
            <p>
              We will notify you over email if there are any activity on the
              shortlist request
            </p>
          </div>
        </div>
        <Divider />
        <div className="p-4">
          <p className="font-semibold text-grey-800">
            Email Id*{` `}
            {!isFalsy(minRequired) && (minRequired ?? 0) > 0 && (
              <span className="text-xs font-light">
                ( Minimum {minRequired} email
                {(minRequired ?? 0) > 1 ? `s are` : ` is `}
                required)
              </span>
            )}
          </p>
          <ValueChipTexFieldWithState
            validateInput={() => true}
            displayMax={displayMax}
            valueSuggestions={valueSuggestions}
            values={values}
            onChange={setEmails}
            disableDefaultValuesDelete={disableDefaultValuesDelete}
          />
        </div>
        <div className="w-full sticky bottom-0 bg-white shadow-t-lg px-4">
          <Button
            disableElevation
            size="large"
            variant="contained"
            className="w-full normal-case font-sans my-4 "
            onClick={() => {
              onSubmit(emails);
              hideModal(MODAL_TYPES.EMAIL_CONFIRMATION);
            }}
            disabled={(minRequired ?? 0) > emails.length}
          >
            Submit request
          </Button>
        </div>
      </div>
    </div>
  );
};
export default EmailConfirmationModal;
