import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import { MappingResponse } from '@/types/Services';

export const configApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getMapping: builder.query<MappingResponse, void>({
      query: () => `/v1/documents/mapping`,
    }),
  }),
});

export const { useGetMappingQuery } = configApi;
