import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cmsBaseApi = createApi({
  reducerPath: `cmsBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_CMS_URL }),
  endpoints: () => ({}),
});

export const cmsBaseApiWithTags = cmsBaseApi.enhanceEndpoints({
  addTagTypes: [`PillarPages`],
});
