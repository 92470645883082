import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { Button, GBTypography } from '@leapfinance/geebee-component-library';
import { ThemeProvider } from '@mui/system';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import useModal from '@/components/Modals/useModal';
import { useStartGteProcessMutation } from '@/services/gteServices';

const GTEModal = () => {
  const { hideModal } = useModal();
  const [triggerStartGteProcess] = useStartGteProcessMutation();
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const handleStartGteProcess = async () => {
    try {
      const res = await triggerStartGteProcess({
        applicationId: router?.query?.applicationId as unknown as number,
      }).unwrap();
      if (res?.success) {
        enqueueSnackbar(
          `GTE Process Successfully started for the application`,
          {
            variant: `success`,
          },
        );
      }
    } catch (error) {
      console.log(`Error in starting GTE`, error);
      enqueueSnackbar(`Failed to start GTE, please try again later`, {
        variant: `error`,
      });
    } finally {
      hideModal(MODAL_TYPES.GTE_PROCESS);
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="bg-white rounded w-[440px]">
        <ThemeProvider theme={GeebeeTheme}>
          <div className="p-6 flex flex-col gap-3">
            <div className="w-11 h-11 p-2.5 bg-error-100 rounded-full justify-start items-center gap-1 inline-flex">
              <Image
                src="/svgs/red-danger-icon.svg"
                width={24}
                height={24}
                alt="Warning"
              />
            </div>
            <div className="flex flex-col gap-2">
              <GBTypography variant="h2Semibold">
                Start GTE Process
              </GBTypography>
              <GBTypography variant="body4" className="mt-1 text-grey-800">
                Are you sure, you want to start the GTE Process for the student?
              </GBTypography>
            </div>
          </div>
          <div className="border-t border-grey-200 flex  gap-x-2 bg-[#FAFAFA] px-6 py-3">
            <Button
              variant="secondary"
              className=" normal-case "
              onClick={() => hideModal(MODAL_TYPES.GTE_PROCESS)}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              className=" whitespace-nowrap "
              onClick={handleStartGteProcess}
              fullWidth
            >
              Start Process
            </Button>
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default GTEModal;
