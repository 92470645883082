import React, { useCallback, useEffect, useState } from 'react';
import {
  type DropEvent,
  type FileRejection,
  useDropzone,
} from 'react-dropzone';

import { GBTypography } from '@leapfinance/geebee-component-library';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from '@mui/material';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  closeUploadDocumentModal,
  DocumnetUploadType,
  InitialProps,
} from '@/features/uploadDocuments/uploadDocuments';
import { useUploadAndSaveMutation } from '@/services/documentUpload';
import { getFileSize } from '@/utils/fileUtilities';

import CustomSortReactSelect from './CustomComponents/CustomSortReactSelect';
import CustomButton from './CustomMUIComponents/CustomButton';

enum UploadStatus {
  UPLOADING = `UPLOADING`,
  UPLOADED = `UPLOADED`,
  FAILED = `FAILED`,
  IDLE = `IDLE`,
}

export interface FileWithType {
  [key: string]: {
    file: File;
    key: string;
    status: UploadStatus;
    url?: string;
  };
}

const FileUploadv2 = () => {
  const {
    onModalClose,
    uploadTitle,
    subDocumentOptions,
    uploadType,
    applicationId,
    studentId,
    referenceLabelKey,
    referenceLabelId,
    defaultKey,
    getMiscLabel,
    maxFiles,
    overrideDocId,
  } = useAppSelector(
    (state: { uploadDocument: InitialProps }) => state.uploadDocument,
  );

  const dispatch = useAppDispatch();
  const [uploadedFiles, setuploadedFiles] = useState<File[]>([]);
  const [filesWithType, setFilesWithType] = useState<FileWithType>({});
  const [optionsSelected, setOptionsSelected] = useState<boolean>(true);
  const [openMiscTitle, setOpenMiscTitle] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>(``);
  const [uploadAndSaveTrigger] = useUploadAndSaveMutation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setOpenMiscTitle(getMiscLabel);
  }, [getMiscLabel]);

  useEffect(() => {
    setModalTitle(uploadTitle);
  }, [uploadTitle]);

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      fileRejections.forEach(({ file, errors }, index) => {
        errors.forEach((error) => {
          const errorMessages = [];
          if (error.code === `file-invalid-type`)
            errorMessages.push(`${file.name} has invalid file type`);
          if (error.code === `file-too-large`)
            errorMessages.push(`${file.name} exceeds 35 MB file limit`);
          if (error.code === `too-many-files`)
            errorMessages.push(
              `Only max ${maxFiles} ${
                (maxFiles || 0) > 1 ? `files` : `file`
              } can be uploaded`,
            );
          enqueueSnackbar(errorMessages, { variant: `warning` });
        });
      });
    },
    [],
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 35000000,
    maxFiles,
    onDrop,
    useFsAccessApi: false,
    accept: {
      'image/*': [
        `.jpg`,
        `.jpeg`,
        `.pdf`,
        `.csv`,
        `.xls`,
        `.xlxs`,
        `.png`,
        `.svg`,
        `.docx`,
        `.doc`,
        `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
        `application/vnd.ms-excel`,
      ],
    },
  });

  useEffect(() => {
    // map over files with key obj and get files
    const files = uniqBy(
      [...uploadedFiles, ...acceptedFiles],
      (file) => file.name,
    );
    setuploadedFiles(files);
    const filesWithKeyTemp = {} as FileWithType;
    acceptedFiles.forEach((file: File) => {
      if (!filesWithType[file.name]) {
        filesWithKeyTemp[file.name] = {
          file,
          key: subDocumentOptions.length === 0 ? defaultKey || `` : ``,
          status: UploadStatus.IDLE,
        };
      }
    });
    setFilesWithType({ ...filesWithKeyTemp, ...filesWithType });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    const subOptionNotSelected = Object.values(filesWithType).some(
      ({ key }) => key === ``,
    );
    if (subOptionNotSelected && subDocumentOptions.length > 0) {
      setOptionsSelected(false);
    } else {
      setOptionsSelected(true);
    }
  }, [filesWithType]);

  const removeFile = (id: number) => {
    const updatedFiles = uploadedFiles.filter((_, index) => id !== index);
    const filesWithKeyTemp = filesWithType;
    delete filesWithKeyTemp[uploadedFiles[id].name];
    setFilesWithType({ ...filesWithKeyTemp });
    setuploadedFiles(updatedFiles);
  };

  const documentKeyOptionHandler = ({
    event,
    file,
  }: {
    event: any;
    file: File;
  }) => {
    setFilesWithType({
      ...filesWithType,
      [file.name]: {
        file,
        key: event.value,
        status: UploadStatus.IDLE,
      },
    });
  };

  const uploadFileHandler = () => {
    Object.keys(filesWithType).forEach(async (key: string) => {
      if (filesWithType[key].status === UploadStatus.UPLOADED) return;
      try {
        setFilesWithType((filesWithType) => ({
          ...filesWithType,
          [key]: { ...filesWithType[key], status: UploadStatus.UPLOADING },
        }));
        let url: any;
        let res: any;

        if (uploadType === DocumnetUploadType.APPLICATION) {
          res = await uploadAndSaveTrigger({
            file: filesWithType[key].file,
            applicationId: applicationId,
            studentId: studentId,
            key: filesWithType[key].key,
            id: overrideDocId,
          }).unwrap();
          url = res.data.url;
        }

        if (uploadType === DocumnetUploadType.STUDENT) {
          res = await uploadAndSaveTrigger({
            file: filesWithType[key].file,
            studentId: studentId,
            key: filesWithType[key].key,
            referenceLabel: referenceLabelKey,
            referenceLabelId,
            id: overrideDocId,
          }).unwrap();
          url = res.data.url;
        }

        if (uploadType === DocumnetUploadType.INDEPENDENT) {
          const payload: any = {
            file: filesWithType[key].file,
            studentId: studentId,
            key: filesWithType[key].key,
            referenceLabel: referenceLabelKey,
            id: overrideDocId,
          };

          if (referenceLabelKey === `MISCELLANEOUS`) {
            payload[`fileLabel`] = modalTitle;
          }

          res = await uploadAndSaveTrigger(payload).unwrap();
          url = res.data.url;
        }
        if (!url) throw new Error(`No file url was passed`);
        setFilesWithType((filesWithType) => ({
          ...filesWithType,
          [key]: {
            ...filesWithType[key],
            status: UploadStatus.UPLOADED,
            ...res.data,
          },
        }));
      } catch (error) {
        setFilesWithType((filesWithType) => ({
          ...filesWithType,
          [key]: { ...filesWithType[key], status: UploadStatus.FAILED },
        }));
        console.log(error);
      }
    });
  };

  const retryUpload = async (key: string) => {
    // try {
    //   setFilesWithType((filesWithType) => ({
    //     ...filesWithType,
    //     [key]: { ...filesWithType[key], status: UploadStatus.UPLOADING },
    //   }));
    //   let url: any;
    //   let res: any;
    //   if (uploadhandler) {
    //     res = await uploadhandler(
    //       filesWithType[key].file,
    //       filesWithType[key].key,
    //     );
    //     url = res.url;
    //   } else {
    //     res = await upload({
    //       file: filesWithType[key].file,
    //       key: filesWithType[key].key,
    //       studentId,
    //     }).unwrap();
    //     const { data } = res;
    //     url = data[0].url;
    //   }
    //   if (!url) throw new Error(`No file url was passed`);
    //   setFilesWithType((filesWithType) => ({
    //     ...filesWithType,
    //     [key]: { ...filesWithType[key], status: UploadStatus.UPLOADED, url },
    //   }));
    // } catch (error) {
    //   setFilesWithType((filesWithType) => ({
    //     ...filesWithType,
    //     [key]: { ...filesWithType[key], status: UploadStatus.FAILED },
    //   }));
    //   console.log(error);
    // }
  };

  const pendingFiles = Object.values(filesWithType).filter(
    (obj) => obj.status !== UploadStatus.UPLOADED,
  );

  const handleClose = () => {
    const files: any = Object.values(filesWithType).filter(
      (obj) => obj.status === UploadStatus.UPLOADED,
    );
    if (onModalClose) onModalClose(files);
    setuploadedFiles([]);
    setFilesWithType({});
    dispatch(closeUploadDocumentModal());
  };

  return (
    <Modal
      open={Boolean(onModalClose)}
      sx={{ justifyContent: `center`, alignItems: `center` }}
    >
      <div className="flex items-center justify-center  h-full">
        <div className=" bg-white p-8 w-[80%] h-[100%] rounded-lg relative">
          <>
            <div className="flex justify-between items-center">
              <div>
                <GBTypography variant="h2Semibold">{modalTitle}</GBTypography>
              </div>
              <div>
                <IconButton onClick={handleClose} className="no-disable-button">
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            {openMiscTitle ? (
              <div className="flex flex-col h-full  justify-center  p-8">
                <div className="flex flex-col items-center justify-center drop-shadow-xl border bg-grey-100 rounded-lg p-8">
                  <p className="py-4 text-lg font-medium">
                    Enter Miscellaneous Documents group name
                  </p>
                  <TextField
                    id="file-label"
                    label="File label"
                    variant="outlined"
                    className="w-full"
                    onChange={(e) => setModalTitle(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <CustomButton
                          text="Save"
                          onClick={() => setOpenMiscTitle(false)}
                          //   disabled={miscellaneousLabel === ``}
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                {/**File upload placeholder */}
                <div className="py-8 px-8 row-start-1 row-end-2">
                  <div className=" border border-dashed border-black rounded-lg cursor-pointer hover:opacity-100">
                    <div
                      {...getRootProps({ className: `dropzone` })}
                      className="flex items-center justify-center p-12"
                      style={{ height: `inherit` }}
                    >
                      <input {...getInputProps()} accept=".pdf" />
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-primary-800 font-semibold text-lg">
                          Drag and drop a document
                        </p>

                        <p className="py-4 text-primary-800 font-semibold text-lg">
                          or
                        </p>
                        <p className="p-2 bg-primary-100 rounded-md">
                          Browse from Computer
                        </p>
                        <p className="text-xs font-extralight">
                          * Max file size is 35mb
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/**Uploaded files list */}
                <div className="row-start-2 row-end-5 flex flex-col border-t border-b  py-2 pl-8  bg-white custom-scroll overflow-y-scroll h-[45%]">
                  <p className=" text-primary-800  mb-2 py-2 ">
                    <GBTypography variant="sh1Semibold">
                      Uploaded Documents
                    </GBTypography>
                  </p>
                  <div className="bg-white">
                    {uploadedFiles.map((file, index) => (
                      <div
                        className="flex justify-between items-center py-4 border-b"
                        key={index}
                      >
                        <div className="flex items-start justify-center">
                          {filesWithType[file.name].status !==
                            UploadStatus.UPLOADED && (
                            <IconButton
                              onClick={() => removeFile(index)}
                              className="no-disable-button"
                            >
                              <CloseIcon className="text-[14px]" />
                            </IconButton>
                          )}

                          <div
                            title={file.name}
                            className="flex flex-col items-start justify-center"
                          >
                            <GBTypography
                              variant="body4"
                              className={`text-primary-800 ${
                                filesWithType[file.name].status ===
                                UploadStatus.UPLOADED
                                  ? `text-green-500`
                                  : filesWithType[file.name].status ===
                                    UploadStatus.FAILED
                                  ? `text-error-500`
                                  : ``
                              }`}
                            >
                              {file.name.substring(0, 30)}
                            </GBTypography>
                            <p className="text-grey-600 ">
                              <GBTypography variant="caption1">
                                {getFileSize(file.size, true)}
                              </GBTypography>
                            </p>
                          </div>
                          <div className="ml-4" title="uploading">
                            {filesWithType[file.name].status ===
                              UploadStatus.UPLOADING && (
                              <CircularProgress size={15} />
                            )}
                            {filesWithType[file.name].status ===
                              UploadStatus.FAILED && (
                              <CustomButton
                                variant="outlined"
                                text="retry uploading"
                                className="text-[0.5rem] px-2 text-red-500 border-red-500 hover:bg-red-200"
                                onClick={() => retryUpload(file.name)}
                              />
                            )}
                          </div>
                        </div>

                        {subDocumentOptions.length > 0 && (
                          <CustomSortReactSelect
                            onChange={(event) =>
                              documentKeyOptionHandler({ event, file })
                            }
                            options={subDocumentOptions}
                            placeholder="Select Document type"
                            styles={{
                              placeholder: (styles) => ({
                                ...styles,
                                fontSize: `0.7rem`,
                              }),
                              input: (styles) => ({
                                ...styles,
                                fontSize: `0.7rem`,
                              }),
                              container: (styles) => ({
                                ...styles,
                                fontSize: `0.7rem`,
                                width: `25%`,
                                marginRight: `1rem`,
                              }),
                            }}
                            menuPlacement={`auto`}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/**Footer */}
                <div className="absolute bottom-0 left-[50%] -translate-x-[50%] drop-shadow-xl flex self-auto  items-center justify-center p-8">
                  <Tooltip
                    open={!optionsSelected}
                    title="Please select all documents type to upload"
                    arrow
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={`text-md normal-case	font-sans ${
                        pendingFiles.length === 0 || !optionsSelected
                          ? `bg-grey-300`
                          : `bg-primary-500`
                      }`}
                      disableElevation={true}
                      disabled={pendingFiles.length === 0 || !optionsSelected}
                      onClick={uploadFileHandler}
                    >
                      Upload All Documents
                      {pendingFiles.length !== 0 && `(${pendingFiles.length})`}
                    </Button>
                  </Tooltip>

                  <Button
                    variant="contained"
                    color="primary"
                    className={` text-md normal-case mx-4	font-sans ${
                      !Object.values(filesWithType).some(
                        (obj) => obj.status === UploadStatus.UPLOADED,
                      )
                        ? `bg-grey-300`
                        : `bg-primary-500`
                    }`}
                    disableElevation={true}
                    disabled={
                      !Object.values(filesWithType).some(
                        (obj) => obj.status === UploadStatus.UPLOADED,
                      )
                    }
                    onClick={handleClose}
                  >
                    Done
                  </Button>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadv2;
