import { type CourseFinderRequestDto } from '@leapfinance/frontend-commons/components/geebee/cf/filters/types';
import { formKeyToFilterKey } from '@leapfinance/frontend-commons/components/geebee/cf/utils';
import { mergeObjectArray } from '@leapfinance/frontend-commons/helpers';

import { store } from '@/app/store';
import { definitions } from '@/types/schema';
import {
  GetSuggestionsDataPathRequestType,
  GetUniversitiesRequestType,
} from '@/types/Services';

import { universityApi } from '../University/universityService';

import { geebeeCfApi } from './services/geebeeCf';

import { UNIVERSITIES_PAGE_SIZE } from './constants';
import { isFalsy } from './helpers';

interface IFetchCourseSuggestion {
  body: GetSuggestionsDataPathRequestType[`courseFinderRequestDto`];
}

export class GetCFSuggestionsUtil {
  // static fetchCourseCategoryAndSameCategorySuggestionData = (
  //   data: IFetchCourseSuggestion,
  // ): Promise<FetchCourseSuggestionReturnType> => {
  //   return new Promise(async (resolve) => {
  //     const { body } = data;
  //     let categoryData: GetSuggestionsDataPathResponseType[`data`];
  //     let sameCategoryData: GetSuggestionsDataPathResponseType[`data`];

  //     try {
  //       const response = await Promise.all([
  //         GetCFSuggestionsUtil.fetchCouseSuggestionData({
  //           aggregation: true,
  //           courseFinderRequestDto: body,
  //         }),
  //       ]);
  //       categoryData = response[0]?.data;
  //       // sameCategoryData = response[1]?.data;
  //       const combinedAggregations = GetCFSuggestionsUtil.mergeAggregations(
  //         categoryData?.aggregations ?? [],
  //         sameCategoryData?.aggregations ?? [],
  //       );

  //       const tempFilterMap: Record<
  //         string,
  //         definitions['CfAggregationResponseDto']
  //       > = {};
  //       combinedAggregations.forEach((filter) => {
  //         if (!filter?.title) return;
  //         if (tempFilterMap[filter.title]) {
  //           const newOptions = mergeObjectArray([
  //             tempFilterMap[filter.title]?.options ?? [],
  //             filter?.options ?? [],
  //           ]);
  //           tempFilterMap[filter.title] = { ...filter, options: newOptions };
  //         } else {
  //           tempFilterMap[filter.title] = filter;
  //         }
  //       });

  //       resolve({
  //         selectedCategoryTab: categoryData?.selectedCategoryTab,
  //         selectedCategoryTabCriteria:
  //           categoryData?.selectedCategoryTabCriteria,
  //         studentShortlistCount: categoryData?.studentShortlistCount,
  //         categoryDataCourses: categoryData?.courseList ?? [],
  //         sameCategoryDataCourses: sameCategoryData?.courseList ?? [],
  //         aggregations: combinedAggregations,
  //         totalCategoryCourses: Number(categoryData?.total ?? 0),
  //         totalSameCategoryCourses: Number(sameCategoryData?.total ?? 0),
  //         totalResultsMoreThanLimit:
  //           categoryData?.totalResultsMoreThanLimit ||
  //           sameCategoryData?.totalResultsMoreThanLimit,
  //         universityUsps: categoryData?.universityUsp,
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       resolve({
  //         selectedCategoryTab: null,
  //         selectedCategoryTabCriteria: null,
  //         studentShortlistCount: null,
  //         categoryDataCourses: [],
  //         sameCategoryDataCourses: [],
  //         totalSameCategoryCourses: 0,
  //         totalCategoryCourses: 0,
  //         aggregations: [],
  //         totalResultsMoreThanLimit: false,
  //         universityUsps: [],
  //       });
  //     }
  //   });
  // };

  static fetchCouseSuggestionData = async (
    data: GetSuggestionsDataPathRequestType & { customPageSize?: number },
  ) => {
    try {
      const {
        aggregation,
        courseFinderRequestDto,
        customPageSize,
        sameSpecialisation,
        sameSubStream,
      } = data;
      const totalPagesToFetch = customPageSize ?? 30;
      //get selected filter keys
      const selectedFilters: string[] = [];
      Object.entries(courseFinderRequestDto).forEach(([key, value]) => {
        if (
          (!isFalsy(value) &&
            typeof formKeyToFilterKey?.[key as keyof CourseFinderRequestDto] !==
              `undefined`) ||
          (Array.isArray(value) && value.length > 0)
        ) {
          selectedFilters.push(
            formKeyToFilterKey?.[key as keyof CourseFinderRequestDto] ?? ``,
          );
        }
      });

      const response = await store
        .dispatch(
          //change this service according to project
          geebeeCfApi.endpoints.getSuggestionsDataV2.initiate(
            {
              aggregation,
              courseFinderRequestDto: {
                ...courseFinderRequestDto,
                selectedFilter: Array.from(
                  new Set(selectedFilters.filter((key) => key !== ``)),
                ),
                totalPerPage: totalPagesToFetch,
              },
              sameSpecialisation,
              sameSubStream,
            },
            { fixedCacheKey: `get-course-suggestions` },
          ),
        )
        .unwrap();

      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  static fetchEligibleAndNonEligibleData = async (
    data: IFetchCourseSuggestion,
  ) => {
    try {
      const response = await Promise.all([
        await GetCFSuggestionsUtil.fetchCouseSuggestionData({
          aggregation: true,
          courseFinderRequestDto: {
            ...data.body,
            selectedCategoryTab: `ELIGIBLE`,
          },
          sameSpecialisation: false,
          sameSubStream: false,
        }),
        await GetCFSuggestionsUtil.fetchCouseSuggestionData({
          aggregation: true,
          courseFinderRequestDto: {
            ...data.body,
            selectedCategoryTab: `NON_ELIGIBLE`,
          },
          sameSpecialisation: false,
          sameSubStream: false,
        }),
      ]);

      return response;
    } catch (error) {
      console.log(error);
      return [null, null];
    }
  };

  static mergeAggregations = (aggregations1: any[], aggregations2: any[]) => {
    const combinedAggregations = [aggregations1, aggregations2].flat();

    const tempFilterMap: Record<
      string,
      definitions['CfAggregationResponseDto']
    > = {};
    combinedAggregations.forEach((filter) => {
      if (!filter?.title) return;
      if (tempFilterMap[filter.title]) {
        const newOptions = mergeObjectArray([
          tempFilterMap[filter.title]?.options ?? [],
          filter?.options ?? [],
        ]);
        tempFilterMap[filter.title] = { ...filter, options: newOptions };
      } else {
        tempFilterMap[filter.title] = filter;
      }
    });

    return Object.values(tempFilterMap);
  };

  static fetchUniversities = async (
    data: GetUniversitiesRequestType & { customPageSize?: number },
  ) => {
    try {
      const { customPageSize, pageIndex, ...body } = data;
      const totalPagesToFetch = customPageSize ?? UNIVERSITIES_PAGE_SIZE;
      //get selected filter keys
      const selectedFilters: string[] = [];
      Object.entries(body).forEach(([key, value]) => {
        if (
          (!isFalsy(value) &&
            typeof formKeyToFilterKey?.[key as keyof CourseFinderRequestDto] !==
              `undefined`) ||
          (Array.isArray(value) && value.length > 0)
        ) {
          selectedFilters.push(
            formKeyToFilterKey?.[key as keyof CourseFinderRequestDto] ?? ``,
          );
        }
      });

      const response = await store
        .dispatch(
          //change this service according to project
          universityApi.endpoints.getUniversities.initiate(
            {
              ...body,
              totalPerPage: totalPagesToFetch,
              selectedFilter: selectedFilters,
              pageIndex: pageIndex ?? 1,
            },
            {
              fixedCacheKey: `cf-universities${
                body?.sameSpecialisation
                  ? `-sameSpecialisation`
                  : body?.sameSubStream
                  ? `-sameSubStream`
                  : ``
              }`,
            },
          ),
        )
        .unwrap();

      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
}
