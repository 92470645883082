import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const bookmarkListBaseApi = createApi({
  reducerPath: `bookmarkListApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/geebeeRoute?path=/curated-list` }),
  endpoints: () => ({}),
});

export const bookmarkListBaseApiWithTags = bookmarkListBaseApi.enhanceEndpoints(
  {
    addTagTypes: [`Bookmark`, `BookmarkListDetails`],
  },
);
