import { Props } from 'react-joyride';

const wait = (ms: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });

export const getElement = (
  selector: string,
  retryCount: number,
  delay?: number,
): Promise<Element | null> => {
  return new Promise((resolve) => {
    if (retryCount < 0) {
      return resolve(null);
    }
    const element = document.querySelector(selector);

    if (element) return resolve(element);
    return wait(delay ?? 300).then(() => {
      getElement(selector, retryCount - 1, delay).then(resolve);
    });
  });
};

export const enableInteractionProperties: Partial<Props[`steps`][number]> = {
  disableBeacon: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  hideFooter: true,
  spotlightClicks: true,
  styles: {
    options: {
      zIndex: 10000,
    },
  },
};
