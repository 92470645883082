import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { paymentApi } from '@/services/payment';
import { definitions } from '@/types/schema';

const initialState = {} as { paymentInfo: definitions['PaymentDetailsDto'] };
//[Todo]: Move to context or window obj
export const paymentSlice = createSlice({
  name: `paymentInfo`,
  initialState,
  reducers: {
    updatePaymentInfo: (
      state,
      action: PayloadAction<definitions['PaymentDetailsDto']>,
    ) => {
      state.paymentInfo = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      paymentApi.endpoints.getPayment.matchFulfilled,
      (state, { payload }) => {
        state.paymentInfo = payload.data as any;
      },
    );
  },
});

export const { updatePaymentInfo } = paymentSlice.actions;

export const selectPayment = (state: RootState) => state.paymentInfo;

export default paymentSlice.reducer;
