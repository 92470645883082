import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import {
  GetGteApplicationDocumentsListRequestType,
  GetGteApplicationDocumentsListResponseType,
  GteCategoryUpdateRequestType,
  GteCategoryUpdateResponseType,
  StartGteRequestType,
  StartGTEResponseType,
} from '@/types/Services';

export const gteApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    fetchApplicationLevelGteDocuments: builder.query<
      GetGteApplicationDocumentsListResponseType,
      GetGteApplicationDocumentsListRequestType
    >({
      query: ({ applicationId }) => ({
        url: `/gte/documents/${applicationId}`,
        method: `GET`,
      }),
      providesTags: [`GteApplicationDocs`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetGteApplicationDocumentsListResponseType;
        } else {
          return {
            data,
            message: null,
            success: true,
          } as unknown as GetGteApplicationDocumentsListResponseType;
        }
      },
    }),
    updateGteSubcategory: builder.mutation<
      GteCategoryUpdateResponseType,
      GteCategoryUpdateRequestType
    >({
      query: (body) => ({
        url: `/gte/add-category`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`GteApplicationDocs`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GteCategoryUpdateResponseType;
        } else {
          return {
            data,
            message: null,
            success: true,
          } as unknown as GteCategoryUpdateResponseType;
        }
      },
    }),
    startGteProcess: builder.mutation<
      StartGTEResponseType,
      StartGteRequestType
    >({
      query: ({ applicationId }) => ({
        url: `/gte/start/${applicationId}`,
        method: `POST`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as StartGTEResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as StartGTEResponseType;
        }
      },
      invalidatesTags: [`Application`, `documents`, `SingleApplication`],
    }),
  }),
});

export const {
  useStartGteProcessMutation,
  useFetchApplicationLevelGteDocumentsQuery,
  useLazyFetchApplicationLevelGteDocumentsQuery,
  useUpdateGteSubcategoryMutation,
} = gteApi;
