import { ReactEventHandler } from 'react';

import {
  Button,
  GBThemeWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import Add from '@mui/icons-material/Add';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolIcon from '@mui/icons-material/School';
import { Divider, Tooltip } from '@mui/material';
import Image from 'next/image';

import { CFCourse } from '@/components/CF/Dashbaord/helpers';
import { getTutionfee } from '@/components/Modals/Application/helpers';

interface IEligibleCourseCard {
  courseDetails: CFCourse;
  isCourseShortlisted?: boolean;
  onShortlistSuccess?: () => void;
  onUniversityNameClick?: () => void;
  isShortlistRequested?: boolean;
}

const EligibleCourseCard = ({
  courseDetails,
  isCourseShortlisted,
  onShortlistSuccess,
  onUniversityNameClick,
  isShortlistRequested,
}: IEligibleCourseCard) => {
  const handleImageFailure: ReactEventHandler<HTMLImageElement> = (
    event,
  ): void => {
    event.currentTarget.src = `/assets/icons/course-finder/university-icon.svg`;
  };

  return (
    <GBThemeWrapper>
      <div className="bg-primary-50 border border-grey-100 py-3 px-4 rounded-lg h-full w-full">
        {/* Name and action cta */}

        <div className="flex justify-between items-center gap-x-4">
          <div className="flex items-center gap-x-3">
            <div className="relative rounded box-border w-10 h-10 overflow-hidden shrink-0 border-[1px] border-solid border-grey-100">
              <Image
                className="object-fill h-full w-full"
                src={
                  courseDetails?.imageUrl ??
                  `/assets/icons/course-finder/university-icon.svg`
                }
                onError={handleImageFailure}
                layout="fill"
                alt="University Logo"
              />
            </div>
            <Tooltip
              title={
                onUniversityNameClick && courseDetails?.courseId
                  ? `Click to view Course Details`
                  : ``
              }
              placement="top"
              arrow={true}
            >
              <GBTypography
                variant="sh2Semibold"
                className={
                  onUniversityNameClick && courseDetails?.courseId
                    ? `text-primary-700 hover:underline cursor-pointer`
                    : `text-primary-700`
                }
                onClick={() => {
                  if (onUniversityNameClick && courseDetails?.courseId) {
                    onUniversityNameClick?.();
                  }
                }}
              >
                {courseDetails?.courseName}
              </GBTypography>
            </Tooltip>
          </div>
          <Button
            className={
              isCourseShortlisted
                ? `read-only-cta whitespace-nowrap min-w-fit text-success-700 bg-success-200`
                : `read-only-cta whitespace-nowrap min-w-fit`
            }
            startIcon={isCourseShortlisted ? <AddTaskIcon /> : <Add />}
            onClick={() => {
              if (isCourseShortlisted) return;
              onShortlistSuccess?.();
            }}
            disabled={isShortlistRequested}
          >
            {isCourseShortlisted
              ? `Added to Shortlist`
              : isShortlistRequested
              ? `Shortlisting ...`
              : `Add to Shortlist`}
          </Button>
        </div>
        {/* Location */}
        <div className="flex items-center ml-[52px] mt-2 justify-between">
          <div className="flex items-center w-1/2">
            <SchoolIcon className="text-grey-600 mr-1.5" fontSize="medium" />
            <GBTypography className="text-grey-700" variant="caption1">
              {courseDetails?.universityName}
            </GBTypography>
          </div>
          <div className="flex items-center w-1/2">
            <LocationOnIcon className="text-grey-600 mr-1.5" fontSize="small" />
            <GBTypography className="text-grey-700" variant="caption1">
              {courseDetails?.cityName}, {courseDetails?.countryName}
            </GBTypography>
          </div>
        </div>
        {/* Divider */}
        <Divider className="my-3 text-grey-200" />
        {/* Fees and details */}
        <div className="flex justify-between gap-x-3">
          <div className="flex items-center gap-x-1.5">
            <Image
              src="/assets/icons/course-finder/currency.svg"
              height={32}
              width={32}
              alt="Currency"
            />
            <div className="flex flex-col gap-1">
              <GBTypography variant="caption1" className="text-grey-700">
                Application Fee
              </GBTypography>
              <GBTypography variant="body4Semibold" className="text-grey-900">
                {courseDetails?.tuitionFeeCurrencyCode}
                {` `}
                {courseDetails?.applicationFee}
              </GBTypography>
            </div>
          </div>
          <div className="flex items-center gap-x-1.5">
            <Image
              src="/assets/icons/course-finder/currency.svg"
              height={32}
              width={32}
              alt="Currency"
            />
            <div className="flex flex-col gap-1">
              {` `}
              <GBTypography variant="caption1" className="text-grey-700">
                Tuition Fee
              </GBTypography>
              <GBTypography variant="body4Semibold" className="text-grey-900">
                {getTutionfee(courseDetails)}
              </GBTypography>
            </div>
          </div>
          <div className="flex items-center gap-x-1.5">
            <Image
              src="/assets/icons/course-finder/duration.svg"
              height={32}
              width={32}
              alt="Duration"
            />
            <div className="flex flex-col gap-1">
              <GBTypography variant="caption1" className="text-grey-700">
                Duration
              </GBTypography>
              <GBTypography variant="body4Semibold" className="text-grey-900">
                {courseDetails?.duration} Months
              </GBTypography>
            </div>
          </div>
        </div>
      </div>
    </GBThemeWrapper>
  );
};

export default EligibleCourseCard;
