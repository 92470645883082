import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const engagementBaseApi = createApi({
  reducerPath: `engagementBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/geebeeRoute?path=/engagement` }),
  endpoints: () => ({}),
});

export const engagementBaseApiWithTags = engagementBaseApi.enhanceEndpoints({
  addTagTypes: [],
});
