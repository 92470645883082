export const EventNames = {
  PAGE_VIEW: `Page viewed`,
  LOGIN: `Login`,
  SIGN_IN: `Sign in`,
  SIGN_UP: `Sign up`,
  FORGOT_PASSWORD: `Forgot Password`,
  RESET_PASSWORD: `Reset Password`,
  CARD_ADDED: `Card Added`,
  DOCUMENT_UPLOAD: `Document Upload`,
  CLICK: `Click`,
  VIEW: `View`,
};
