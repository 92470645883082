import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as {
  message: string;
  title: string;
  onSubmit: undefined | (() => void);
  onCancel?: () => void;
};

const confirmDialogSlice = createSlice({
  name: `confirmDialog`,
  initialState,
  reducers: {
    close: (state) => {
      state.onSubmit = undefined;
    },
    confirmDialog: (
      state,
      action: PayloadAction<{
        message: string;
        title: string;
        onSubmit: () => void;
        onCancel?: () => void;
      }>,
    ) => {
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.onSubmit = action.payload.onSubmit;
      state.onCancel = action.payload.onCancel;
    },
  },
});

export const { close, confirmDialog } = confirmDialogSlice.actions;

export default confirmDialogSlice.reducer;
