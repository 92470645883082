import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: `#443EFF`,
    },
  },
  typography: {
    fontFamily: `Montserrat, sans-serif`,
  },
  components: {
    MuiButton: { defaultProps: { style: { textTransform: `none` } } },
  },
});

export default theme;
