import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const studentDashboardApi = createApi({
  reducerPath: `studentDashboardApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/student-dashboard` }),
  endpoints: () => ({}),
});

export const studentDashboardApiWithTags = studentDashboardApi.enhanceEndpoints(
  {
    addTagTypes: [`Application`],
  },
);
