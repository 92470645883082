import React from 'react';

import { Button } from '@leapfinance/frontend-commons';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';

import { ProfileSections } from '../StudentProfileSection/helpers';

import { MODAL_TYPES } from './GlobalModal';
import useModal from './useModal';
export interface IProfileUnsavedChangesModal {
  sections: ProfileSections[];
  onCancel: () => void;
  onProceed: () => void;
}
const ProfileUnsavedChangesModal: React.FC<IProfileUnsavedChangesModal> = ({
  sections,
  onCancel,
  onProceed,
}) => {
  const { hideModal } = useModal();

  const handleAction = (action: boolean) => {
    if (action) {
      onProceed();
    } else {
      onCancel();
    }
    hideModal(MODAL_TYPES.PROFILE_UNSAVE_MODAL);
  };
  return (
    <ThemeProvider theme={GeebeeTheme}>
      <div className="w-full h-full flex items-center justify-center">
        <div className="bg-white p-4 flex flex-col  w-[520px]  rounded-md drop-shadow-lg">
          <div className="self-center">
            <WarningAmberRoundedIcon className="h-12 w-12 text-warning-400" />
          </div>

          <Typography variant="h3" className="text-gray-700">
            Changes for below section have not been saved. Do you want to
            proceed further ?
          </Typography>
          <div className="p-2">
            {sections.map((section) => (
              <>{section}</>
            ))}
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Button
              variant="outlined"
              className="w-full"
              onClick={() => handleAction(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="w-full"
              onClick={() => handleAction(true)}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ProfileUnsavedChangesModal;
