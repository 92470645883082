import { store } from '@/app/store';
import { convertInputToReadableCurrencyFormat } from '@/components/CF/Dashbaord/helpers';
import { definitions } from '@/types/schema';

export const getUniversityLocation = (
  courseInfo?: definitions['CoursesResponseDto'],
) => {
  let location = ``;
  if (courseInfo?.city) {
    location += courseInfo?.city;
  }
  if (location === `` && courseInfo?.state) {
    location += courseInfo?.state;
  }
  if (courseInfo?.country) {
    if (location === ``) {
      location += courseInfo?.country;
    } else {
      location += `, ${courseInfo?.country}`;
    }
  }
  return location;
};

export const courseIndexTableHeaders = [
  { name: `S.No` },
  { name: `Course Name` },
  { name: `View Details`, hideHeader: true },
];

export const getTuitionfee: (
  courseData?: definitions['CoursesResponseDto'],
) => string | null = (courseData) => {
  const dataType: any = [undefined, null, ``, 0];
  if (
    !dataType.includes(courseData?.tuitionFeePerYear) &&
    !dataType.includes(courseData?.tuitionFeePerYearInr)
  ) {
    return `${courseData?.currencyCode} ${convertInputToReadableCurrencyFormat(
      courseData?.tuitionFeePerYear as number,
      courseData?.currencyCode ?? `USD`,
    )}`;
  } else if (
    !dataType.includes(courseData?.tuitionFeeOverall) &&
    !dataType.includes(courseData?.currencyCode)
  ) {
    return `${courseData?.currencyCode} ${convertInputToReadableCurrencyFormat(
      courseData?.tuitionFeeOverall as number,
      courseData?.currencyCode ?? `USD`,
    )}`;
  }
  return null;
};

export const UspImagePdfMapper: Record<string, string> = {
  HIGH_OFFER_RATE: `/assets/icons/course-finder/acceptance-rate-usp.svg`,
  QUICKEST_OFFER_TAT: `/assets/icons/course-finder/quick-offer-usp.svg`,
  GB_APP_FEE_WAIVER: `/assets/icons/course-finder/affordability-usp.svg`,
  HIGH_RANKING_INSTITUTE: `/assets/icons/course-finder/ranking-usp.svg`,
  PLACEMENT_SUPPORT: `/assets/icons/course-finder/placement-support-usp.svg`,
  AFFORDABILITY: `/assets/icons/course-finder/affordability-usp.svg`,
  MOI_ACCEPTED: `/assets/icons/course-finder/moi-usp.svg`,
};

export const getRedirectionDomainForPDFDownload = (hostName: string) => {
  const isNewStudentPortalEnabled =
    store?.getState()?.user?.userConfig?.newStudentPortalEnabled;
  const redirectionDomain = isNewStudentPortalEnabled
    ? `https://${hostName}.studyoverseas.ai`
    : `https://${hostName}.unisetu.com`;
  return redirectionDomain;
};
