import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@/app/store';
import { universityAndCourseApi } from '@/services/suggestion';
import { CFDegreeListResponseType } from '@/types/Services';

export type DegreeLists = CFDegreeListResponseType;

const initialState = {} as DegreeLists;

export const degreeListSlice = createSlice({
  name: `degreeLists`,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      universityAndCourseApi.endpoints.getDegreeList.matchFulfilled,
      (state, { payload }) => {
        return payload;
      },
    );
  },
});

export const selectCFDegreeList = (state: RootState) => {
  return state.cfDegreeLists || [];
};

export default degreeListSlice.reducer;
