import { Props } from 'react-joyride';

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

// & {
//   tourName?: MarkProductTourCompleteRequestType['productTourType'];
// }

const initialState: Props = {
  steps: [],
  run: false,
  stepIndex: 0,
};

const joyrideSlice = createSlice({
  name: `joyride`,
  initialState,
  reducers: {
    setJoyrideSteps: (state, action: PayloadAction<Props['steps']>) => {
      state.steps = action.payload as any;
    },
    startJoyride: (state) => {
      state.run = true;
    },
    stopJoyride: (state) => {
      state.run = false;
    },
    toggleJoyRideContinous: (state) => {
      state.continuous = !state.continuous;
    },
    resetJoyride: (state) => {
      state.run = initialState.run;
      state.steps = initialState.steps as any;
      state.stepIndex = initialState.stepIndex;
    },
    updateStepIndex: (state, action: PayloadAction<Props['stepIndex']>) => {
      state.stepIndex = action.payload;
    },
    setJoyRideConfig: (state, action: PayloadAction<Props>) => {
      return {
        ...action.payload,
        steps: action.payload.steps ?? [],
        run: action.payload.run ?? false,
        stepIndex: action.payload.stepIndex ?? 0,
      };
    },
  },
});

export const {
  setJoyrideSteps,
  startJoyride,
  stopJoyride,
  resetJoyride,
  updateStepIndex,
  setJoyRideConfig,
  toggleJoyRideContinous,
} = joyrideSlice.actions;

export const selectJoyrideSteps = (state: { joyride: Props }) =>
  state.joyride.steps;
export const selectJoyrideRun = (state: { joyride: Props }) =>
  state.joyride.run;
export const selectJoyrideStepIndex = (state: { joyride: Props }) =>
  state.joyride.stepIndex;
export const selectJoyRideCallBack = (state: { joyride: Props }) =>
  state.joyride.callback;
// export const selectProductTourName = (
//   state: Props & {
//     tourName?: MarkProductTourCompleteRequestType['productTourType'];
//   },
// ) => state.tourName;

export default joyrideSlice.reducer;
