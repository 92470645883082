import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { FileWithType } from '@/components/FileUploadv2';
import { Option } from '@/types/form';

export enum DocumnetUploadType {
  APPLICATION = `APPLICATION`,
  STUDENT = `STUDENT`,
  MISCELLANEOUS = `MISCELLANEOUS`,
  INDEPENDENT = `INDEPENDENT`,
}

export interface InitialProps {
  uploadType: DocumnetUploadType;
  referenceLabelKey: string;
  referenceLabelId: number;
  studentId: number;
  applicationId: number;
  onModalClose: undefined | ((props: FileWithType[]) => void);
  subDocumentOptions: Option[];
  uploadTitle: string;
  defaultKey: string | null;
  fileLabel: string;
  getMiscLabel: boolean;
  maxFiles?: number;
  overrideDocId?: number;
}

type ActionPayload = {
  subDocumentOptions: Option[];
  onModalClose: (props: FileWithType[]) => void;
  defaultKey?: string | null;
  maxFiles?: number;
  overrideDocId?: number;
};
const initialState = {} as InitialProps;
const uploadDocumentSlice = createSlice({
  name: `uploadDocumentSlice`,
  initialState,
  reducers: {
    closeUploadDocumentModal: (state) => {
      state.onModalClose = undefined;
      state.getMiscLabel = false;
      state.maxFiles = undefined;
      state.overrideDocId = undefined;
    },
    uploadApplicationDocuments: (
      state,
      action: PayloadAction<
        ActionPayload & {
          applicationId: number;
          studentId: number;
          uploadTitle: string;
        }
      >,
    ) => {
      state.uploadTitle = action.payload.uploadTitle;
      state.subDocumentOptions = action.payload.subDocumentOptions;
      state.uploadType = DocumnetUploadType.APPLICATION;
      state.applicationId = action.payload.applicationId;
      state.studentId = action.payload.studentId;
      state.onModalClose = action.payload.onModalClose;
      if (action.payload.defaultKey) {
        state.defaultKey = action.payload.defaultKey;
      }
      if (action.payload.maxFiles) {
        state.maxFiles = action.payload.maxFiles;
      }
      if (action.payload.overrideDocId) {
        state.overrideDocId = action.payload.overrideDocId;
      }
    },
    uploadStudentDocuments: (
      state,
      action: PayloadAction<
        ActionPayload & {
          referenceLabelKey: string;
          referenceLabelId: number;
          studentId: number;
          uploadTitle: string;
        }
      >,
    ) => {
      state.uploadTitle = action.payload.uploadTitle;
      state.subDocumentOptions = action.payload.subDocumentOptions;
      state.uploadType = DocumnetUploadType.STUDENT;
      state.studentId = action.payload.studentId;
      state.onModalClose = action.payload.onModalClose;
      state.referenceLabelKey = action.payload.referenceLabelKey;
      state.referenceLabelId = action.payload.referenceLabelId;
      if (action.payload.defaultKey) {
        state.defaultKey = action.payload.defaultKey;
      }
      if (action.payload.maxFiles) {
        state.maxFiles = action.payload.maxFiles;
      }
      if (action.payload.overrideDocId) {
        state.overrideDocId = action.payload.overrideDocId;
      }
    },
    uploadMiscellaneousaDocuments: (
      state,
      action: PayloadAction<
        ActionPayload & {
          getMiscLabel?: boolean;
          studentId: number;
          uploadTitle: string;
        }
      >,
    ) => {
      state.getMiscLabel = Boolean(action.payload.getMiscLabel);
      state.uploadType = DocumnetUploadType.MISCELLANEOUS;
      state.studentId = action.payload.studentId;
      state.onModalClose = action.payload.onModalClose;
      state.subDocumentOptions = [];
      state.uploadTitle = action.payload.uploadTitle || ``;
      if (action.payload.defaultKey) {
        state.defaultKey = action.payload.defaultKey;
      }
      if (action.payload.maxFiles) {
        state.maxFiles = action.payload.maxFiles;
      }
      if (action.payload.overrideDocId) {
        state.overrideDocId = action.payload.overrideDocId;
      }
    },
    uploadIndependentDocuments: (
      state,
      action: PayloadAction<
        ActionPayload & {
          getMiscLabel?: boolean;
          referenceLabelKey?: string;
          studentId: number;
          uploadTitle?: string;
        }
      >,
    ) => {
      state.getMiscLabel = Boolean(action.payload.getMiscLabel);
      state.uploadType = DocumnetUploadType.INDEPENDENT;
      state.studentId = action.payload.studentId;
      state.onModalClose = action.payload.onModalClose;
      state.subDocumentOptions = action.payload.subDocumentOptions;
      state.uploadTitle = action.payload?.uploadTitle || ``;
      if (action.payload.referenceLabelKey) {
        state.referenceLabelKey = action.payload.referenceLabelKey;
      }
      if (action.payload.defaultKey) {
        state.defaultKey = action.payload.defaultKey;
      }
      if (action.payload.maxFiles) {
        state.maxFiles = action.payload.maxFiles;
      }
      if (action.payload.overrideDocId) {
        state.overrideDocId = action.payload.overrideDocId;
      }
    },
  },
});

export const {
  uploadApplicationDocuments,
  closeUploadDocumentModal,
  uploadStudentDocuments,
  uploadMiscellaneousaDocuments,
  uploadIndependentDocuments,
} = uploadDocumentSlice.actions;

export default uploadDocumentSlice.reducer;
