import { store } from '@/app/store';
import { setDegreeIntakeMap } from '@/features/courseFinder/cfStateSlice';
import { definitions } from '@/types/schema';

import { geebeeCfApi } from './services/geebeeCf';

export type DegreeIntakeObj = {
  disabledDegrees: number[];
  popularIntakes: string[];
};
export type StudyWhereTagData = DegreeIntakeObj;

type LinearFiltersRequestDto = definitions['GetLinearFilterValuesDto'];
export default class DegreeIntakeUtil {
  /**
   * Singleton instance of DegreeIntakeUtil.
   */
  private static instance: DegreeIntakeUtil;

  /**
   * Private constructor to enforce singleton pattern and initialize the mapping.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  /**
   * Returns the singleton instance of DegreeIntakeUtil.
   * @returns {DegreeIntakeUtil} The singleton instance.
   */
  public static getInstance(): DegreeIntakeUtil {
    if (!DegreeIntakeUtil.instance) {
      DegreeIntakeUtil.instance = new DegreeIntakeUtil();
    }

    return DegreeIntakeUtil.instance;
  }

  /**
   * Fetches and sets the degree intake mapping for a given study tag and ID.
   * @param {StudyWhereTagData} param - Object containing study tag and ID.
   */
  public fetchAndSetMapping({ entities }: LinearFiltersRequestDto) {
    store
      .dispatch(
        geebeeCfApi.endpoints.getLinearFilterValues.initiate({
          entities,
        }),
      )
      .unwrap()
      .then((data) => {
        if (data?.success) {
          const degreeIds: number[] =
            data?.data?.degreesV2
              ?.filter((data) => !data?.preferred)
              .map((d) => d?.id as number) ?? [];
          const intakes: string[] =
            data?.data?.intakes
              ?.filter((data) => data?.preferred)
              .map((d) => JSON.stringify(d?.months)) ?? [];
          store.dispatch(
            setDegreeIntakeMap({
              disabledDegrees: degreeIds,
              popularIntakes: intakes,
            }),
          );
        }
      });
  }

  /**
   * Retrieves an array of disabled degrees based on an array of study tags and IDs.
   * @param {StudyWhereTagData[]} tagsData - Array of study tags and IDs.
   * @returns {number[][]} Array of disabled degrees.
   */
  // public getDisbaledDegrees(tagsData: StudyWhereTagData[]) {
  //   const degreeIntakeMapping =
  //     store.getState().cfStateSlice.degreeIntakeMapping;
  //   if (!degreeIntakeMapping) return [[]];
  //   const disabledDegrees: number[][] = [];
  //   tagsData.forEach(({ id, tag }) => {
  //     if (tag && id) {
  //       const key = `${tag}-${id}`;
  //       const degreeIntake = degreeIntakeMapping?.[key];
  //       if (
  //         Array.isArray(degreeIntake?.disabledDegrees) &&
  //         !isFalsy(degreeIntake?.disabledDegrees)
  //       ) {
  //         disabledDegrees.push(degreeIntake?.disabledDegrees as number[]);
  //       }
  //     }
  //   });
  //   return disabledDegrees;
  // }

  /**
   * Retrieves an array of popular intakes based on an array of study tags and IDs.
   * @param {StudyWhereTagData[]} tagsData - Array of study tags and IDs.
   * @returns {string[]} Array of popular intakes.
   */
  // public getPopularIntakes(tagsData: StudyWhereTagData[]) {
  //   const degreeIntakeMapping =
  //     store.getState().cfStateSlice.degreeIntakeMapping;
  //   if (!degreeIntakeMapping) return [];
  //   const popularIntakes: string[] = [];
  //   tagsData.forEach(({ tag, id }) => {
  //     const key = `${tag}-${id}`;
  //     const degreeIntake = degreeIntakeMapping?.[key];
  //     if (
  //       Array.isArray(degreeIntake?.popularIntakes) &&
  //       !isFalsy(degreeIntake?.popularIntakes)
  //     ) {
  //       popularIntakes.push(
  //         ...((degreeIntake?.popularIntakes as string[]) ?? []),
  //       );
  //     }
  //   });
  //   return popularIntakes;
  // }
}

DegreeIntakeUtil.getInstance();
