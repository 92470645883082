import jwtDecode from 'jwt-decode';

export type DecodedToken = {
  readonly email: string;
  readonly exp: number;
  readonly user_id?: {
    user_id: string;
    application: string;
    roles: string[];
    tenant: string;
  };
};

export const TOKEN_STORAGE_KEY = `leapscholarportal.authToken`;

export class AuthToken {
  readonly decodedToken: DecodedToken;

  constructor(readonly token?: string) {
    // we are going to default to an expired decodedToken
    this.decodedToken = { email: ``, exp: 0 };

    // then try and decode the jwt using jwt-decode
    try {
      if (token) this.decodedToken = jwtDecode(token);
    } catch (e) {}
  }

  getData() {
    return `Abhijeet Gairola`;
  }

  get is_sales(): boolean {
    return this.decodedToken.user_id?.roles?.includes(`SALES`) || false;
  }

  get is_counsellor(): boolean {
    return this.decodedToken.user_id?.roles?.includes(`COUNSELLOR`) || false;
  }

  get authorizationString() {
    return `Bearer ${this.token}`;
  }

  get expiresAt(): Date {
    return new Date(this.decodedToken.exp * 1000);
  }

  get isExpired(): boolean {
    return new Date() > this.expiresAt;
  }

  get isValid(): boolean {
    return !this.isExpired;
  }

  get is_freelancer(): boolean {
    return this.decodedToken.user_id?.roles?.includes(`FREELANCER`) || false;
  }

  get is_teacher(): boolean {
    return this.decodedToken.user_id?.roles?.includes(`TEACHER`) || false;
  }

  get tenant(): string {
    return this.decodedToken.user_id?.tenant || ``;
  }
}
