import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { Column } from 'exceljs';

import { SingleCFCourse } from './helpers';
export const CATEGORY_TOTAL_CARDS_PER_PAGE = 30;
export const SAME_CATEGORY_TOTAL_CARDS_PER_PAGE = 60;
export const UNIVERSITIES_PAGE_SIZE = 60;
export const DEFAULT_TAGS_KEY = `cf-default-tags`;
export const DEFAULT_DESTINATION_KEY = `cf-default-destination`;
export const POPULAR_COUNTRIES: OPTION_TYPE[] = [
  { value: 1, label: `Australia` },
  { value: 2, label: `UK` },
  { value: 3, label: `Ireland` },
  { value: 4, label: `Canada` },
  { value: 5, label: `USA` },
  { value: 6, label: `NewZealand` },
];

export const DEGREE_OPTIONS: OPTION_TYPE[] = [
  {
    value: 36,
    label: `Postgraduate`,
  },
  {
    value: 32,
    label: `PG Diploma /Certificate`,
  },
  {
    value: 33,
    label: `Undergraduate`,
  },
  {
    value: 35,
    label: `UG+PG (Accelerated) Degree`,
  },
  {
    value: 3,
    label: `Ph.D.`,
  },
  {
    label: `UG Diploma /Certificate /Associate Degree`,
    value: 34,
  },
];

export const CSVKEYS = [
  `universityName`,
  `categoryName`,
  `courseUrl`,
  `cityName`,
  `courseName`,
  `countryName`,
  `tuitionFeeOverallInr`,
  `tuitionFeePerYearInr`,
  `duration`,
  `intakeMonth`,
  `rank`,
  `ieltsScore`,
  `toeflScore`,
  `twelfthScore100`,
  `ugScore100`,
  `greRequired`,
  `gmatRequired`,
] as Array<keyof SingleCFCourse>;

export const CFCoursesCSVHeader: Array<Partial<Column>> = [
  { header: `Intitute Name`, key: `universityName` as keyof SingleCFCourse },
  { header: `Course Name`, key: `courseName` as keyof SingleCFCourse },
  { header: `Course Link`, key: `courseUrl` as keyof SingleCFCourse },
  { header: `Institute City`, key: `cityName` as keyof SingleCFCourse },
  {
    header: `Institute Country`,
    key: `countryName` as keyof SingleCFCourse,
  },
  {
    header: `Tution fee Overall (INR)`,
    key: `tuitionFeeOverallInr` as keyof SingleCFCourse,
  },
  {
    header: `First Year Tution fee (INR)`,
    key: `tuitionFeePerYearInr` as keyof SingleCFCourse,
  },
  { header: `Duration`, key: `duration` as keyof SingleCFCourse },
  { header: `Intakes Offered`, key: `intakeMonth` as keyof SingleCFCourse },
  { header: `College Rank`, key: `rank` as keyof SingleCFCourse },
  { header: `IELTS`, key: `ieltsScore` as keyof SingleCFCourse },
  { header: `TOFEL`, key: `toeflScore` as keyof SingleCFCourse },
  { header: `12th Score`, key: `twelfthScore100` as keyof SingleCFCourse },
  { header: `UG Score`, key: `ugScore100` as keyof SingleCFCourse },
  { header: `GRE Required`, key: `greRequired` as keyof SingleCFCourse },
  { header: `GMAT Required`, key: `gmatRequired` as keyof SingleCFCourse },
];

export enum Inputype {
  ASYNC_DROPDOWN = `async-dropdown`,
  DROPDOWN = `dropdown`,
  RAW_INPUT = `raw-input`,
}

export enum CFResultTabs {
  COURSES,
  UNIVERSITIES,
}
