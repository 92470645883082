import { useEffect, useState } from 'react';

import { getCookie } from '@/lib/auth-cookies-new';
import { AuthToken, DecodedToken } from '@/lib/auth-token';

function useLoginToken() {
  const [userDetails, setUserDetails] = useState<DecodedToken | null>(null);

  useEffect(() => {
    try {
      const token = getCookie(null, `token`);
      const auth = new AuthToken(token);
      setUserDetails(auth.decodedToken);
    } catch (err) {
      setUserDetails(null);
    }
  }, []);

  return [userDetails];
}

export default useLoginToken;
