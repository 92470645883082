import { seoRootBaseApiWithTags } from '@/services/base/seoRoute';
import {
  CFCourseSearchRequestType,
  CFCOurseSearchResponseType,
  CFDegreeListRequestType,
  CFDegreeListResponseType,
  CollegeSuggestionRequestType,
  CollegeSuggestionResponseType,
} from '@/types/Services';

import { geebeeRootBaseApi } from './base/geebeeRoute';

export const universityAndCourseApi = seoRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    fetchUniversity: builder.mutation<
      CollegeSuggestionResponseType,
      CollegeSuggestionRequestType
    >({
      query: (payload) => ({
        url: `college/suggestions`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [`university`],
    }),
    fetchCourseCategories: builder.mutation({
      query: (payload) => ({
        url: `course/category/suggestions`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [`course`],
    }),
    getDegreeList: builder.query<
      CFDegreeListResponseType,
      CFDegreeListRequestType
    >({
      query: () => ({ url: `course/degrees/listAll`, method: `GET` }),
    }),
  }),
});

export const universityAndCourseApiGeebee = geebeeRootBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCourse: builder.mutation<
      CFCOurseSearchResponseType,
      CFCourseSearchRequestType
    >({
      query: (payload) => ({
        url: `cf/course/suggestion`,
        method: `POST`,
        body: payload,
      }),
      // invalidatesTags: [`course`],
    }),
  }),
});

export const {
  useFetchUniversityMutation,
  useFetchCourseCategoriesMutation,
  useGetDegreeListQuery,
} = universityAndCourseApi;

export const { useFetchCourseMutation } = universityAndCourseApiGeebee;
