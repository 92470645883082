import { GBLocalStorageUtil, localStorageKeys } from './GBLocalStorageUtil';

export const MODAL_DISBALE_DATE = `2024/1/31`;

// Helper function to get the current time in hours (24-hour format)
function getCurrentHour(): number {
  return new Date().getHours();
}

// Helper function to check if a given time falls within a specified range
function isTimeInRange(
  currentHour: number,
  startHour: number,
  endHour: number,
): boolean {
  return currentHour >= startHour && currentHour < endHour;
}

type ShouldShowPopUpParams = {
  key: localStorageKeys;
  skipTimeSlot: boolean;
  maxTimesToShow: number;
  minGapHours?: number;
  modalDisableDateTime?: string;
};

// Function to check if a pop-up should be shown
export function shouldShowPopup({
  key,
  skipTimeSlot,
  maxTimesToShow,
  minGapHours,
  modalDisableDateTime,
}: ShouldShowPopUpParams): boolean {
  // Get the minimum gap hours, defaulting to 4 if not provided
  const minGapHoursLocal = minGapHours ?? 4;

  // Get the current date and time
  const currentDateTime = new Date();
  const currentDate = currentDateTime.toISOString().slice(0, 10);
  const currentHour = currentDateTime.getHours();

  // Get user data from localStorage
  const lastPopupTimes: string[] =
    GBLocalStorageUtil.handleReadLocalStorageData(key) ?? [];

  // If a modalDisableDateTime is provided, check if the current date and time is after the disable date and time, and return false if so
  if (modalDisableDateTime) {
    const disableDateTime = new Date(modalDisableDateTime);
    const disableDate = disableDateTime.toISOString().slice(0, 10);
    const disableHour = disableDateTime.getHours();

    if (
      currentDate > disableDate ||
      (currentDate === disableDate && currentHour >= disableHour)
    ) {
      return false;
    }
  }

  // Get the total popup count
  const popupCount = lastPopupTimes.length;

  // If the user has seen the popup the maximum number of times, return false
  if (popupCount >= maxTimesToShow) {
    return false;
  }

  // Get the last popup time, if any
  const lastPopupTime =
    popupCount > 0 ? new Date(lastPopupTimes[popupCount - 1]) : null;

  // Check if the current time is within the allowed time slots
  const isWithinFirstTimeSlot = isTimeInRange(currentHour, 11, 15);
  const isWithinSecondTimeSlot = isTimeInRange(currentHour, 16, 19);

  // If the current time is not within the allowed time slots and skipTimeSlot is false, return false
  if (!skipTimeSlot && !(isWithinFirstTimeSlot || isWithinSecondTimeSlot)) {
    return false;
  }

  // If there was a previous popup, check if the minimum gap time has passed
  if (lastPopupTime) {
    const hoursSinceLastPopup =
      (currentDateTime.getTime() - lastPopupTime.getTime()) / (1000 * 60 * 60);
    if (hoursSinceLastPopup < minGapHoursLocal) {
      return false;
    }
  }

  // If all checks pass, return true to show the popup
  return true;
}

export const markModalShown = (key: localStorageKeys) => {
  const lastPopupTimes: string[] =
    GBLocalStorageUtil.handleReadLocalStorageData(key) ?? [];
  lastPopupTimes.push(new Date().toISOString());
  GBLocalStorageUtil.handleSetLocalStorageData(key, lastPopupTimes);
};
