import React from 'react';
import ReactJoyride from 'react-joyride';

import { useAppSelector } from '@/app/hooks';

export const backButtonTypography = {
  fontFamily: `Montserrat`,
  fontSize: `0.75rem`,
  fontStyle: `normal`,
  fontWeight: 500,
  lineHeight: `1rem`,
  color: `#0A0926`,
};

export const nextButtonTypography = {
  color: `#FFF`,
  fontFamily: `Montserrat`,
  fontSize: `0.75rem`,
  fontStyle: `normal`,
  fontWeight: 500,
  lineHeight: `1rem`, // Note: The comment is included for reference but has no effect on the style
};

export const skipButtonTypography = {
  color: `#000`,
  fontFamily: `Montserrat`,
  fontSize: `0.75rem`,
  fontStyle: `normal`,
  fontWeight: 500,
  lineHeight: `1rem`, // Note: The comment is included for reference but has no effect on the style
  textDecorationLine: `underline`,
};

export const tooltipContentTypography = {
  color: `#17154D`,
  fontFamily: `Montserrat`,
  fontSize: `0.875rem`,
  fontStyle: `normal`,
  fontWeight: 600,
  lineHeight: `1.25rem`, // Note: The comment is included for reference but has no effect on the style
};

function JoyrideComponent() {
  const joyRideConfig = useAppSelector((state) => state.joyride);

  //merging predefined styles with one passed from slice.
  const mergedStyles = Object.assign(
    {},
    {
      buttonBack: {
        ...backButtonTypography,
        padding: `4px 8px`,
        borderRadius: `0.25rem`,
        border: `1px solid #1B1D6A`,
      },
      buttonNext: {
        ...nextButtonTypography,
        padding: `4px 8px`,
        borderRadius: `0.25rem`,
        backgroundColor: `#25228C`,
        border: `1px solid #25228C`,
      },
      buttonSkip: {
        ...skipButtonTypography,
      },
      tooltipContent: {
        ...tooltipContentTypography,
        padding: `0px`,
      },
      overlay: {
        backgroundColor: `black`,
        opacity: `0.8`,
      },
    },
    joyRideConfig.styles ?? {},
  );

  return <ReactJoyride {...joyRideConfig} styles={mergedStyles} />;
}

export default JoyrideComponent;
