import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

const initialState = {} as { recentApplicationRemarkResponse: any };
export const applicationSlice = createSlice({
  name: `application`,
  initialState,
  reducers: {
    updateRecentApplicationRemarkResponse: (
      state,
      action: PayloadAction<{ recentApplicationRemarkResponse: any }>,
    ) => {
      state.recentApplicationRemarkResponse = action.payload;
    },
  },
});

export const { updateRecentApplicationRemarkResponse } =
  applicationSlice.actions;

export const selectRecentApplicationRemarkResponse = (state: RootState) =>
  state.applicationData.recentApplicationRemarkResponse;

export default applicationSlice.reducer;
