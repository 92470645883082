import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import useModal from '@/components/Modals/useModal';
import { localStorageKeys } from '@/utils/GBLocalStorageUtil';
import { markModalShown } from '@/utils/newsletterFeatureUtil';

type Props = {
  redirectionUrl: string;
  imageUrl: string;
  storageKey: localStorageKeys;
  onImageClick?: () => void;
};
const LoanMelaAwarenessPopper = ({
  redirectionUrl,
  imageUrl,
  storageKey,
  onImageClick,
}: Props) => {
  const { hideModal } = useModal();

  return (
    <div className="flex justify-center items-center h-full">
      <div className="bg-white flex flex-col rounded-lg max-w-[500px] m-5  relative">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={imageUrl}
          width={`100%`}
          height={260}
          alt="representation"
          onClick={() => {
            window.open(redirectionUrl, `_blank`);
            onImageClick?.();
          }}
          className="cursor-pointer"
        />
        <IconButton
          className="absolute -top-3 -right-3 bg-white p-1"
          onClick={() => {
            markModalShown(storageKey);
            hideModal(MODAL_TYPES.NO_RESULTS);
          }}
        >
          <Close className="text-black" />
        </IconButton>
      </div>
    </div>
  );
};

export default LoanMelaAwarenessPopper;
