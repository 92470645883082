import axios, { type AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';

export const downloadCSVData = async (
  payload: any,
  url: string,
  handleState?: (loading?: boolean) => void,
) => {
  try {
    const config: AxiosRequestConfig = {
      method: `POST`,
      url: url,
      responseType: `blob`,
      data: payload,
    };
    handleState && handleState?.(true);
    const res = await axios(config);
    handleState && handleState?.(false);
    if (res && res.data && res.data.size > 0) {
      const fileName = res.headers[`content-disposition`].split(`filename=`)[1];
      saveAs(res.data, fileName);
    }
  } catch (err) {
    throw new Error(`Something went wrong. Please contact the tech team`);
  }
};
