import { CourseFinder } from '@/types/courseFinder';
import {
  BaseResponse,
  CmsSpecialisationsRequestType,
  CmsSpecialisationsResponseType,
  GetUniversityDataRequestType,
  GetUniversityDataResponseType,
  ShortListResponse,
} from '@/types/Services';

import { geebeeRootBaseApiWithTags } from './base/geebeeRoute';

import { transformResponse } from './utils';

export const courseFinderApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getShortlists: builder.query<ShortListResponse, { studentId: number }>({
      query: ({ studentId }) => `/v1/shortlist/student/${studentId}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as ShortListResponse;
        } else {
          return { data } as unknown as ShortListResponse;
        }
      },
      // providesTags: (result) =>
      //   // is result available?
      //   result?.data?.shortlist
      //     ? // successful query
      //       [
      //         ...result.data.shortlist.map(
      //           ({ id }) => ({ type: `Shortlist`, id } as const),
      //         ),
      //         { type: `Shortlist`, id: `LIST` },
      //       ]
      //     : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
      //       [{ type: `Shortlist`, id: `LIST` }],
      providesTags: [`ShortlistRequest`],
    }),
    updateShortlist: builder.mutation<
      BaseResponse<any>,
      { studentId: number; shortlistData: CourseFinder }
    >({
      query: ({ studentId, shortlistData }) => ({
        url: `/v1/shortlist/update/student/${studentId}`,
        method: `PUT`,
        body: shortlistData,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BaseResponse<any>;
        } else {
          return { data } as unknown as BaseResponse<any>;
        }
      },
      invalidatesTags: [`ShortlistRequest`],
    }),
    deleteShortlist: builder.mutation<
      BaseResponse<any>,
      { studentId: number; shortlistId: number }
    >({
      query: ({ studentId, shortlistId }) => ({
        url: `/v1/shortlist/${shortlistId}/student/${studentId}`,
        method: `DELETE`,
      }),
      transformResponse,
      invalidatesTags: [`ShortlistRequest`],
    }),
    getUniversityData: builder.query<
      GetUniversityDataResponseType,
      GetUniversityDataRequestType
    >({
      query: ({ cmsInstituteId }) => ({
        url: `cf/cms-institute/${cmsInstituteId}`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetUniversityDataResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetUniversityDataResponseType;
        }
      },
    }),
    getCFCourseDetailsById: builder.query<any, { id: number }>({
      query: ({ id }) => `cf/course/${id}`,
    }),
    getCmsSpecialisations: builder.query<
      CmsSpecialisationsResponseType,
      CmsSpecialisationsRequestType
    >({
      query: ({ totalPerPage, pageIndex, tags, specialisations }) => ({
        url: `/cf/specialisations`,
        method: `POST`,
        body: {
          totalPerPage,
          pageIndex,
          tags,
          specialisations,
        },
      }),
    }),
  }),
});

export const {
  useGetShortlistsQuery,
  useDeleteShortlistMutation,
  useUpdateShortlistMutation,
  useLazyGetShortlistsQuery,
  useGetUniversityDataQuery,
  useGetCFCourseDetailsByIdQuery,
  useLazyGetCFCourseDetailsByIdQuery,
  useGetCmsSpecialisationsQuery,
} = courseFinderApi;
