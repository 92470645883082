import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import { transformResponse } from '@/services/utils';
import {
  GetUniversitiesRequestType,
  GetUniversititesResponseType,
  GetUniversityCoursesRequestType,
  GetUniversityCoursesResponseType,
  GetUniversityRequestType,
  GetUniversityResponseType,
} from '@/types/Services';

export const universityApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUniversityDetails: builder.query<
      GetUniversityResponseType,
      GetUniversityRequestType
    >({
      query: ({ id }) => `/cf/university/${id}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetUniversityResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetUniversityResponseType;
        }
      },
    }),
    getUniversityCourses: builder.mutation<
      GetUniversityCoursesResponseType,
      GetUniversityCoursesRequestType
    >({
      query: (body) => ({
        url: `/cf/university/courses`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetUniversityCoursesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetUniversityCoursesResponseType;
        }
      },
    }),

    getUniversities: builder.mutation<
      GetUniversititesResponseType,
      GetUniversitiesRequestType
    >({
      query: ({ sameSpecialisation, sameSubStream, ...body }) => ({
        url: `/cf/universities`,
        method: `POST`,
        body,
        params: {
          sameSpecialisation,
          sameSubStream,
        },
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useGetUniversityDetailsQuery,
  useGetUniversityCoursesMutation,
  useGetUniversitiesMutation,
} = universityApi;
