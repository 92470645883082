import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import {
  GetApplicationPaymentRequestType,
  GetApplicationPaymentResponseType,
  UpdatePaymentRequestType,
  UpdatePaymentResponseType,
} from '@/types/Services';

export const paymentApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPayment: builder.query<
      GetApplicationPaymentResponseType,
      GetApplicationPaymentRequestType
    >({
      query: ({ applicationId }) => `/payment/${applicationId}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetApplicationPaymentResponseType;
        } else {
          return { data } as unknown as GetApplicationPaymentResponseType;
        }
      },
      providesTags: [`Payment`],
    }),
    updatePayment: builder.mutation<
      UpdatePaymentResponseType,
      UpdatePaymentRequestType
    >({
      query: (body) => ({
        url: `/payment`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdatePaymentResponseType;
        } else {
          return { data } as unknown as UpdatePaymentResponseType;
        }
      },
      invalidatesTags: [`Payment`, `Student`],
    }),
  }),
});

export const { useUpdatePaymentMutation, useGetPaymentQuery } = paymentApi;
