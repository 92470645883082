// /v1/onboarding-form/csp-details/{cspId}
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const onboardingFormBaseApi = createApi({
  reducerPath: `onboardingFormBaseApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `/api/geebeeRoute?path=/v1/onboarding-form`,
  }),
  endpoints: () => ({}),
});

export const onboardingFormBaseApiWithTags =
  onboardingFormBaseApi.enhanceEndpoints({
    addTagTypes: [`CSPDetails`],
  });
