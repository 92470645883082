import React, { useEffect } from 'react';

import { isFalsy } from '@leapfinance/frontend-commons';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import { selectUserDetails } from '@/features/user/userSlice';
import { Permissions } from '@/permissions/featuresList';
import { trackClick } from '@/segment/eventTracker';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';
import { localStorageKeys } from '@/utils/GBLocalStorageUtil';
import { shouldShowPopup } from '@/utils/newsletterFeatureUtil';

import { MODAL_TYPES } from './Modals/GlobalModal';
import useModal from './Modals/useModal';
import LoanMelaAwarenessPopper from './Tiering/LoanMelaAwarenessPopper';

const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;

const LOAN_MELA_AWARENESS_POPUP_DISBALE_DATE = `2024-11-08 13:00:00`;

const AnnouncementWrapper = (props: { children: React.ReactNode }) => {
  const { showModal, hideModal } = useModal();
  const router = useRouter();

  const cspProfileData = useAppSelector((state) => selectUserDetails(state));

  const isInternationalCsp = cspProfileData?.cspZone === `International`;

  const isStudentUser =
    cspProfileData?.permissions?.[0] === Permissions.STUDENT;

  useEffect(() => {
    if (
      isFalsy(cspProfileData?.permissions) ||
      isStudentUser ||
      !router?.isReady
    )
      return;
    const routesWhereToNotShow = [
      `/`,
      `/login`,
      `/changepwd`,
      `/resetpwd`,
      `/test-prep/video-course`,
      `/test-prep/video-course/[slug]`,
      `/test-prep/mock-test`,
      `/test-prep/mock-test/[mock_test_id]`,
      `/test-prep/mock-test/result/[mock_test_id]`,
      `/test-prep/mock-test/speaking/result/[id]`,
      `/ielts`,
      `/student-login`,
    ];

    // Display IELTS adoption popper first
    // const ieltsBannerDisplayedDates =
    //   GBLocalStorageUtil.handleReadLocalStorageData(
    //     localStorageKeys.IELTS_ADOPTION_POPUP_DISPLAYED,
    //   ) ?? [];
    // const today = new Date()?.toISOString()?.split(`T`)?.[0];

    // if (
    //   // !routesWhereToNotShow?.includes(router?.pathname?.toString()) &&
    //   // !ieltsBannerDisplayedDates?.includes(today) &&
    //   // ieltsBannerDisplayedDates?.length < 3
    //   false
    // ) {
    //   showModal({
    //     modalType: MODAL_TYPES.NO_RESULTS,
    //     modalProps: {
    //       body: <IELTSAdoptionPopper />,
    //     },
    //   });
    //   GBLocalStorageUtil.handleSetLocalStorageData(
    //     localStorageKeys.IELTS_POPUP_DISPLAYED_TIME,
    //     new Date(),
    //   );
    //   return;
    // }

    // Display GB Loans modal next only after 2 hours from IELTS popup display
    // const showLoansUpsellModal = shouldShowPopup({
    //   key: localStorageKeys.SHOW_LOANS_UPSELL_COUNT,
    //   maxTimesToShow: 3,
    //   skipTimeSlot: true,
    // });

    // const lastIeltsPopupDisplayedTime =
    //   GBLocalStorageUtil?.handleReadLocalStorageData(
    //     localStorageKeys.IELTS_POPUP_DISPLAYED_TIME,
    //   ) ?? new Date();
    // const currentTime = new Date();

    // const timeElapsedFromLastIeltsPopupDisplay =
    //   (currentTime as any) - (new Date(lastIeltsPopupDisplayedTime) as any);

    // if (
    //   // showLoansUpsellModal &&
    //   // !routesWhereToNotShow?.includes(router?.pathname?.toString()) &&
    //   // ![`/rewards-club`]?.includes(router?.pathname?.toString()) &&
    //   // !isInternationalCsp &&
    //   // timeElapsedFromLastIeltsPopupDisplay > twoHoursInMilliseconds
    //   false
    // ) {
    //   showModal({
    //     modalType: MODAL_TYPES.LOANS_REQUEST_UPSELL,
    //     modalProps: {
    //       onClose: () => {
    //         markModalShown(localStorageKeys.SHOW_LOANS_UPSELL_COUNT);
    //         hideModal();
    //       },
    //     },
    //   });
    //   return;
    // }

    // Display Commissions modal next
    // const showPromotedInstituteModal = shouldShowPopup({
    //   key: localStorageKeys.LAST_POPUP_TIMES,
    //   maxTimesToShow: 5,
    //   skipTimeSlot: false,
    // });

    // if (showPromotedInstituteModal && isPromoteInstituteBannerVisible) {
    //   showModal({
    //     modalType: MODAL_TYPES.NEWSLETTER_ANNOUNCEMENT,
    //     modalProps: {
    //       onClose: () => {
    //         markModalShown(localStorageKeys.LAST_POPUP_TIMES);
    //         hideModal();
    //       },
    //     },
    //   });
    //   return;
    // }

    const showLoanMelaAwarenessModal = shouldShowPopup({
      key: localStorageKeys.SHOW_EUROPE_COUNSELLING_UPSELL_V2,
      maxTimesToShow: 5,
      skipTimeSlot: false,
      // modalDisableDateTime: LOAN_MELA_AWARENESS_POPUP_DISBALE_DATE,
    });

    if (
      showLoanMelaAwarenessModal &&
      !cspProfileData?.permissions?.includes(`SUB_AGENT`) &&
      !isInternationalCsp &&
      !routesWhereToNotShow?.includes(router?.pathname?.toString())
    ) {
      showModal({
        modalType: MODAL_TYPES.NO_RESULTS,
        modalProps: {
          body: (
            <LoanMelaAwarenessPopper
              redirectionUrl={`https://forms.gle/UU7PyhnTpGCxMCsA6`}
              imageUrl="https://ik.imagekit.io/onsnhxjshmp/geebee-emails/paid%20Counselling_RX6dRqZ8Q.png?updatedAt=1731576756674"
              storageKey={localStorageKeys.SHOW_EUROPE_COUNSELLING_UPSELL_V2}
              onImageClick={() => {
                trackClick({
                  pageName: PageNames.SUMMARY_DASHBOARD,
                  widgetName: WidgetName.GTM_MODAL,
                  widgetFormat: WidgetFormat.MODAL,
                  contentFormat: `Button`,
                  contentName: `Get Started`,
                  featureName: `Paid Counselling Europe`,
                });
              }}
            />
          ),
        },
      });
    }
  }, [router, isInternationalCsp, cspProfileData]);

  return <>{props.children}</>;
};

export default AnnouncementWrapper;
