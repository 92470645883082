import { isFalsy } from '@leapfinance/frontend-commons';

import { CFCourse } from '@/components/CF/Dashbaord/helpers';
import { definitions } from '@/types/schema';

export const generateEligibleCoursesMessage = ({
  studentName,
  college,
  course,
  eligibleCourses,
  type,
  nonEligibleReasons,
}: {
  studentName: string;
  course: string;
  college: string;
  eligibleCourses: CFCourse[];
  type: 'clipboard' | 'whatsapp';
  nonEligibleReasons?: definitions['CourseNonEligibilityReasonDto'][];
}) => {
  const transformedReasons = transformNonEligibleReasons(nonEligibleReasons);
  const eligibleCourseDetails = eligibleCourses?.map((course, index) => {
    return [
      `${index + 1}. ${course?.courseName}`,
      `   Application Fee: ${course?.tuitionFeeCurrencyCode} ${course?.applicationFee}`,
      `   Tuition Fee: ${getTutionfee(course)}`,
    ].join(`\n`);
  });

  const textMessage = `Hey ${studentName}, you're not eligible for ${course} at ${college} due to:

${transformedReasons?.join(`\n`)}

Here are a few similar courses where you might be eligible:

${eligibleCourseDetails?.join(`\n\n`)}

`;

  return type === `whatsapp` ? encodeURIComponent(textMessage) : textMessage;
};

const getReasonDescription = (
  item: definitions['CourseNonEligibilityReasonDto'],
) => {
  let description = ``;
  if (!isFalsy(item?.inverseCondition)) {
    if (item?.inverseCondition) {
      description += `High `;
    } else {
      description += `Low `;
    }
  }
  description += item?.description;
  return description;
};

const transformNonEligibleReasons = (
  reasons?: definitions['CourseNonEligibilityReasonDto'][],
) => {
  if (isFalsy(reasons)) return [];
  return (reasons ?? []).map(
    (item, index) =>
      `* ${getReasonDescription(item)}${
        index === (reasons ?? [])?.length - 2 ? `, and ` : `, `
      }`,
  );
};

export const getTutionfee: (courseData?: CFCourse) => string = (
  courseData?: CFCourse,
) => {
  const dataType: any = [undefined, null, ``, 0];
  if (
    !dataType.includes(courseData?.tuitionFeePerYear) &&
    !dataType.includes(courseData?.tuitionFeePerYearInr)
  ) {
    return `${courseData?.tuitionFeeCurrencyCode} ${courseData?.tuitionFeePerYear}`;
  } else if (
    !dataType.includes(courseData?.tuitionFeeOverall) &&
    !dataType.includes(courseData?.tuitionFeeCurrencyCode)
  ) {
    return `${courseData?.tuitionFeeCurrencyCode} ${courseData?.tuitionFeeOverall}`;
  }
  return `N/A`;
};
