import { isFalsy } from '@leapfinance/frontend-commons';
import router from 'next/router';

import { store } from '@/app/store';
import { deleteCookie, getCookie } from '@/lib/auth-cookies-new';
import { AuthToken } from '@/lib/auth-token';
import SentryError from '@/lib/Sentry';
import { Permissions } from '@/permissions/featuresList';
import { UserConfig } from '@/types';

export const getBaseUrl = (url: string) => {
  try {
    const parsedURL = new URL(url);
    const isProd = parsedURL.hostname.includes(`unisetu.com`);
    if (isProd) {
      const protocol = parsedURL.protocol;
      const token = getCookie(null, `token`);
      const authToken = new AuthToken(token);
      // Get the hostname (e.g., 'www.example.com')
      let hostname;
      if (parsedURL.hostname === `unisetu.com`) {
        // hostname = `${authToken.tenant ?? 'patiala'}.unisetu.com`;
        hostname = `${authToken.tenant}.unisetu.com`;
      } else {
        hostname = `${authToken.tenant}${parsedURL.hostname.substring(
          parsedURL.hostname.indexOf(`.`),
        )}`;
      }
      // Get the port if specified (e.g., '8080', otherwise it will be an empty string)
      const port = parsedURL.port;
      let baseUrl = `${protocol}//${hostname}`;
      if (port) {
        baseUrl += `:${port}`;
      }
      // Outputs something like 'https://www.example.com' or 'https://www.example.com:8080'
      return baseUrl;
    } else {
      return url;
    }
  } catch (err) {
    new SentryError(`Redirection Failed`, err);
    console.error(err);
    return url;
  }
};

export const getSlugAfterHostname = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    let slug = parsedUrl?.pathname?.slice(1); // Remove the leading '/'
    if (parsedUrl?.search) {
      slug += parsedUrl?.search; // add query params, if any
    }
    return slug;
  } catch (error) {
    console.error(`Invalid URL:`, error);
    return ``; // Return an empty string or handle the error as appropriate
  }
};

export const validateIfStudentIsLoggedIn = (auth: AuthToken) => {
  if (!auth) return null;
  const isStudentLoggedIn =
    auth?.decodedToken?.user_id?.roles?.includes(`CSP_STUDENT`);
  return isStudentLoggedIn;
};

export enum LOGIN_SCREEN_ERROR_MESSAGES {
  INVALID_STUDENT_USER_ON_UNISETU = `INVALID_STUDENT_USER_ON_UNISETU`,
  INVALID_STUDENT_USER_ON_STUDYOVERSEAS = `INVALID_STUDENT_USER_ON_STUDYOVERSEAS`,
  INVALID_NON_STUDENT_USER_ON_STUDYOVERSEAS = `INVALID_NON_STUDENT_USER_ON_STUDYOVERSEAS`,
}

export const analyzeUserLogin = ({
  loggedInUserPermissions,
  isNewStudentPortalEnabled,
}: {
  loggedInUserPermissions: UserConfig['permissions'];
  isNewStudentPortalEnabled: UserConfig['newStudentPortalEnabled'];
}) => {
  const isUserLoggedInUnisetu =
    window?.location?.hostname?.includes(`unisetu.com`) ||
    window?.location?.hostname?.includes(`leapscholar.com`);
  const isUserLoggedInStudyOverseas =
    window?.location?.hostname?.includes(`studyoverseas.ai`);
  const isStudentUser = loggedInUserPermissions?.[0] === Permissions.STUDENT;
  const isProd = window?.location?.hostname?.includes(`unisetu.com`);

  if (isStudentUser && isNewStudentPortalEnabled && isUserLoggedInUnisetu) {
    if (isProd) {
      deleteCookie(null, `token`, false, false);
    } else {
      deleteCookie(null, `token`, true, false);
    }
    return LOGIN_SCREEN_ERROR_MESSAGES.INVALID_STUDENT_USER_ON_UNISETU;
  }

  if (!isStudentUser && isUserLoggedInStudyOverseas) {
    deleteCookie(null, `token`, false, true);
    return LOGIN_SCREEN_ERROR_MESSAGES.INVALID_NON_STUDENT_USER_ON_STUDYOVERSEAS;
  }
  if (
    isStudentUser &&
    isUserLoggedInStudyOverseas &&
    !isNewStudentPortalEnabled
  ) {
    deleteCookie(null, `token`, false, true);
    return LOGIN_SCREEN_ERROR_MESSAGES.INVALID_STUDENT_USER_ON_STUDYOVERSEAS;
  }
};

export const getUrlForMisdirectedUsers = () => {
  const loggedInUserPermissions =
    store.getState().user?.userConfig?.permissions;
  const isStudentUser = loggedInUserPermissions?.[0] === Permissions.STUDENT;
  const isDev = window?.location?.hostname?.includes(`leapscholar.com`);
  const loggedInUserDomain = store.getState().user?.userConfig?.domain;
  if (
    !isFalsy(loggedInUserPermissions) &&
    window &&
    window?.location?.hostname?.includes(`studyoverseas.ai`) &&
    !isStudentUser
  ) {
    if (isDev) {
      return `${window?.location?.origin}${router?.pathname}`;
    } else {
      return `https://${loggedInUserDomain}.unisetu.com`;
    }
  }
};

export const isLoggedOutUserEnteringStudyOverseas = () => {
  const token = getCookie(null, `token`);
  const auth = new AuthToken(token);
  return (
    window &&
    window?.location?.hostname?.includes(`studyoverseas.ai`) &&
    router?.route === `/` &&
    (!token || auth.isExpired)
  );
};

export const handleRedirectionForCspsEnabledStudyoverseas = () => {
  const hasCspEnabledStudyOverseas =
    store.getState().user?.userConfig?.newStudentPortalEnabled === true;
  const isUserLoggedInUnisetu =
    window?.location?.hostname?.includes(`unisetu.com`) ||
    window?.location?.hostname?.includes(`leapscholar.com`);
  const isDev = window?.location?.hostname?.includes(`leapscholar.com`);
  const token = getCookie(null, `token`);
  const auth = new AuthToken(token);
  const isStudentLoggedIn = validateIfStudentIsLoggedIn(auth);
  const hostName = window?.location?.host?.split(`.`)[0];
  if (
    hasCspEnabledStudyOverseas &&
    isUserLoggedInUnisetu &&
    isStudentLoggedIn
  ) {
    if (isDev) {
      return `https://${hostName}.studyoverseas.ai${router.pathname}`;
    } else {
      return `https://${auth.tenant}.studyoverseas.ai${router.pathname}`;
    }
  }
};

export const handleRedirectionForCspsDisabledStudyoverseas = () => {
  const hasCspDisabledStudyOverseas =
    store.getState().user?.userConfig?.newStudentPortalEnabled === false ||
    store.getState().user?.userConfig?.newStudentPortalEnabled === null;
  const isUserLoggedInStudyoverseas =
    window?.location?.hostname?.includes(`studyoverseas.ai`);
  const isDev = window?.location?.hostname?.includes(`leapscholar.com`);
  const token = getCookie(null, `token`);
  const auth = new AuthToken(token);
  const isStudentLoggedIn = validateIfStudentIsLoggedIn(auth);
  if (
    hasCspDisabledStudyOverseas &&
    isUserLoggedInStudyoverseas &&
    isStudentLoggedIn
  ) {
    if (isDev) {
      return `${window?.location?.origin}${router.pathname}`;
    } else {
      return `https://${auth.tenant}.unisetu.com${router.pathname}`;
    }
  }
};

export const getPortalLinkForStudentLoginCredsCsv = (domain: string) => {
  const isNewStudentPortalEnabled =
    store?.getState()?.user?.userConfig?.newStudentPortalEnabled;
  const portalLink = isNewStudentPortalEnabled
    ? `https://${domain}.studyoverseas.ai/ielts`
    : `https://${domain}.unisetu.com/ielts`;
  return portalLink;
};
