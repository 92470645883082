import * as React from 'react';

import { Tooltip, tooltipClasses, type TooltipProps } from '@mui/material';

interface IEligiblityToolTipProps extends TooltipProps {
  children: React.ReactElement<any, any>;
}

const EligiblityToolTip: React.FunctionComponent<IEligiblityToolTipProps> = (
  props,
) => {
  return (
    <Tooltip
      arrow
      placement="top"
      PopperProps={{
        disablePortal: true,
        sx: (theme) => ({
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary[`900`],
            padding: `12px 16px 12px 16px`,
            borderRadius: `8px`,
          },
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.primary[`900`],
          },
        }),
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

export default EligiblityToolTip;
