var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"GBQbsjsh4aJJdXtPOtE-J"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

const { SENTRY_DSN } = process.env;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.25,
    integrations: [
      new CaptureConsoleIntegration({
        levels: [`error`],
      }),
      new Sentry.Replay(),
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
      // Specify the error patterns or types to ignore
      'analytics.js',
      /analytics\.js/,
    ],
    async beforeSend(event, hint) {
      // Because beforeSend and beforeBreadcrumb are async, user can fetch some data
      // return a promise, or whatever he wants
      // Our CustomError defined in errors.js has `someMethodAttachedToOurCustomError`
      // which can mimick something like a network request to grab more detailed error info or something.
      // hint is original exception that was triggered, so we check for our CustomError name
      if (hint.originalException.message === 'websocket error') {
        // by sending null we drop this event
        return null;
      }
      return event;
    },
  });
}
