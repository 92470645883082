import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@/app/store';
import { applicationApi } from '@/services/student';
import { definitions } from '@/types/schema';

export type SharedLists = definitions['FilterListDto'];

const initialState = {} as SharedLists;

export const sharedSlice = createSlice({
  name: `sharedLists`,
  initialState,
  reducers: {
    updateList: (state, action: PayloadAction<SharedLists>) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      applicationApi.endpoints.getSharedLists.matchFulfilled,
      (state, { payload }) => {
        return payload;
      },
    );
  },
});

export const { updateList } = sharedSlice.actions;

export const selectList = (state: RootState) => {
  return state.sharedList || [];
};

export default sharedSlice.reducer;
