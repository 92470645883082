import { Option } from '@/types/form';

export const ApplicationConditionalRenderFields: {
  [key: string]: Option[];
} = {
  APPLICATION_RECEIVED: [
    { label: `Application Receive Date`, value: `applicationDate` },
  ],
  APPLICATION_ON_HOLD: [
    { label: `Reason for hold`, value: `reasonForHold` },
    {
      label: `Description of On Hold Reason`,
      value: `reasonForHoldExplanation`,
    },
    { label: `Missing Documents`, value: `onHoldMissingDocuments` },
    { label: `Incorrect Documents`, value: `onHoldIncorrectDocuments` },
  ],
  AGGREGATOR_ON_HOLD_RESOLVED: [
    {
      label: `Description of On Hold Reason`,
      value: `reasonForHoldExplanation`,
    },
  ],
  DOCUMENT_VERIFIED: [
    { label: `Document Verification Date`, value: `documentVerificationDate` },
  ],
  APPLICATION_DROPPED: [
    { label: `Application Drop Date`, value: `applicationDropDate` },
    { label: `Application Drop Reason`, value: `reasonForDrop` },
  ],
  APPLICATION_SENT_TO_UNIVERSITY: [
    { label: `Application Sent Date`, value: `applicationSentDate` },
    { label: `Offer Due Date`, value: `dueDateOffer` },
  ],
  APPLICATION_ON_HOLD_BY_UNIVERSITY: [
    { label: `Reason for hold`, value: `reasonForHoldUniversity` },
    { label: `Missing Documents`, value: `onHoldUniversityMissingDocuments` },
    {
      label: `Incorrect Documents`,
      value: `onHoldUniversityIncorrectDocuments`,
    },
    {
      label: `Description of On Hold Reason`,
      value: `reasonForHoldUniversityExplanation`,
    },
  ],
  UNIVERSITY_ON_HOLD_RESOLVED: [
    {
      label: `Description of On Hold Reason`,
      value: `reasonForHoldUniversityExplanation`,
    },
  ],
  UNIVERSITY_COMMUNICATION_PENDING: [
    { label: `Follow Up Date`, value: `followUpDate` },
  ],
  CONDITIONAL_ADMIT_RECEIVED: [
    { label: `Last Date for Fee Payment`, value: `lastDateFeePayment` },
    {
      label: `Last Date for Offer Acceptance`,
      value: `lastDateOfferAcceptance`,
    },
  ],
  UNCONDITIONAL_ADMIT_RECEIVED: [
    { label: `Last Date for Fee Payment`, value: `lastDateFeePayment` },
    {
      label: `Last Date for Offer Acceptance`,
      value: `lastDateOfferAcceptance`,
    },
  ],
  APPLICATION_REJECTED: [
    { label: `Reason for Rejection`, value: `reasonForRejection` },
  ],
};
export const NonEditableFields: string[] = [
  `Remarks`,
  `Application Status`,
  `Application Receive Date`,
  `Reason for hold`,
  `Description of On Hold Reason`,
  `Missing Documents`,
  `Incorrect Documents`,
  `Document Verification Date`,
  `Application Drop Date`,
  `Application Sent Date`,
  `Follow Up Date`,
  `Last Date for Fee Payment`,
  `Last Date for Offer Acceptance`,
  `Reason for Rejection`,
  `Offer Due Date`,
  `Application Drop Reason`,
];

export enum ApplicationStages {
  DRAFT = `DRAFT`,
  APPLICATION_RECEIVED = `APPLICATION_RECEIVED`,
  DOCUMENT_VERIFIED = `DOCUMENT_VERIFIED`,
  APPLICATION_ON_HOLD = `APPLICATION_ON_HOLD`,
  AGGREGATOR_ON_HOLD_RESOLVED = `AGGREGATOR_ON_HOLD_RESOLVED`,
  APPLICATION_IN_ASSESSMENT = `APPLICATION_IN_ASSESSMENT`,
  APPLICATION_SENT_TO_UNIVERSITY = `APPLICATION_SENT_TO_UNIVERSITY`,
  APPLICATION_ON_HOLD_BY_UNIVERSITY = `APPLICATION_ON_HOLD_BY_UNIVERSITY`,
  UNIVERSITY_ON_HOLD_RESOLVED = `UNIVERSITY_ON_HOLD_RESOLVED`,
  UNIVERSITY_COMMUNICATION_PENDING = `UNIVERSITY_COMMUNICATION_PENDING`,
  APPLICATION_DROPPED = `APPLICATION_DROPPED`,
  APPLICATION_REJECTED = `APPLICATION_REJECTED`,
  CONDITIONAL_ADMIT_RECEIVED = `CONDITIONAL_ADMIT_RECEIVED`,
  CONDITION_SATISFIED = `CONDITION_SATISFIED`,
  ADMIT_DEFER = `ADMIT_DEFER`,
  UNCONDITIONAL_ADMIT_RECEIVED = `UNCONDITIONAL_ADMIT_RECEIVED`,
  ADMIT_DECLINED = `ADMIT_DECLINED`,
  ADMIT_ACCEPTED = `ADMIT_ACCEPTED`,
  TUITION_FEE_PAID = `TUITION_FEE_PAID`,
  DEPOSIT_PAID = `DEPOSIT_PAID`,
  OFFER_REVOKED = `OFFER_REVOKED`,
  UNIVERSITY_PRE_ASSESSMENT = `UNIVERSITY_PRE_ASSESSMENT`,
  REFUNDED = `REFUNDED`,
  NOT_PAID = `NOT_PAID`,
}

export enum ApplicationOnHoldDocuments {
  OnHoldMissingDocuments = `MISSING_DOCUMENTS`,
  OnHoldIncorrectDocuments = `INCORRECT_DOCUMENTS`,
  OnHoldUniversityMissingDocuments = `MISSING_DOCUMENTS`,
  OnHoldUniversityIncorrectDocuments = `INCORRECT_DOCUMENTS`,
}
// export const OnHoldMissingDocuments = `Missing documents`;
// export const OnHoldIncorrectDocuments = `Incorrect documents`;
// export const OnHoldUniversityMissingDocuments = `Missing documents`;
// export const OnHoldUniversityIncorrectDocuments = `Incorrect documents`;
