import React from 'react';

import { Button, type ButtonProps, CircularProgress } from '@mui/material';
import classNames from 'classnames';

type Props = ButtonProps & {
  text: string;
  loading?: boolean;
};
const CustomButton = ({ className, loading, ...props }: Props) => {
  return (
    <Button
      variant={props.variant || `contained`}
      color="primary"
      disableElevation={true}
      className={classNames(`normal-case	font-sans `, className, {
        'bg-grey-500': loading,
      })}
      {...props}
      disabled={props.disabled || loading}
      endIcon={
        loading ? (
          <CircularProgress size={24} className="ml-2 text-white" />
        ) : (
          props.endIcon
        )
      }
    >
      {props.text}
    </Button>
  );
};

export default CustomButton;
